/* eslint-disable */



import React, { useState, useEffect, useContext } from 'react';
import FloormapContext from '../../layouts/Floormap/FloormapContext/FloormapContext';
import FloormapGestionReservacionesContext from './FloormapGestionReservacionesContext/FloormapGestionReservacionesContext';
import prepareCategoryList from '../../utils/prepareCategoryList';
import MenuReservationStatus from '../MenuReservationStatus';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
} from '@material-ui/pickers';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import DateFnsUtils from '@date-io/date-fns';
import es from 'date-fns/locale/es';



import IconButton from '@material-ui/core/IconButton';

import Konva from 'konva';
import { Stage, Layer, Star, Text, Rect, Line, Image, Circle, Group } from 'react-konva';
import useImage from 'use-image';

import moment from 'moment';

import './FloormapGestionReservaciones.scss';

import * as ReservationsApi from '../../api/restaurants/reservations';
import * as FloormapApi from '../../api/restaurants/floormap';
import * as CatalogsApi from '../../api/catalogs';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SearchIcon from '@material-ui/icons/Search';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CloseIcon from '@material-ui/icons/Close';
import DefaulImage from '../../images/default.jpeg';
import DialogImage from '../DialogImage';
import DialogCommentFloormap from '../DialogCommentFloormap';

import IconPeople from '../../images/categoryIcons/people.png';

//Mesas
import mesa1 from '../../images/floormapElements/mesas/mesa1.png';
import mesa2 from '../../images/floormapElements/mesas/mesa2.png';
import mesa3 from '../../images/floormapElements/mesas/mesa3.png';
import mesa4 from '../../images/floormapElements/mesas/mesa4.png';
import mesa5 from '../../images/floormapElements/mesas/mesa5.png';
import mesa6 from '../../images/floormapElements/mesas/mesa6.png';
import mesa7 from '../../images/floormapElements/mesas/mesa7.png';
import mesa8 from '../../images/floormapElements/mesas/mesa8.png';
import mesa9 from '../../images/floormapElements/mesas/mesa9.png';
import mesa10 from '../../images/floormapElements/mesas/mesa10.png';
import mesa11 from '../../images/floormapElements/mesas/mesa11.png';
import mesa12 from '../../images/floormapElements/mesas/mesa12.png';
import mesa13 from '../../images/floormapElements/mesas/mesa13.png';

import mesa1Rosada from '../../images/floormapElements/mesas/mesa1Rosada.png';
import mesa2Rosada from '../../images/floormapElements/mesas/mesa2Rosada.png';
import mesa3Rosada from '../../images/floormapElements/mesas/mesa3Rosada.png';
import mesa4Rosada from '../../images/floormapElements/mesas/mesa4Rosada.png';
import mesa5Rosada from '../../images/floormapElements/mesas/mesa5Rosada.png';
import mesa6Rosada from '../../images/floormapElements/mesas/mesa6Rosada.png';
import mesa7Rosada from '../../images/floormapElements/mesas/mesa7Rosada.png';
import mesa8Rosada from '../../images/floormapElements/mesas/mesa8Rosada.png';
import mesa9Rosada from '../../images/floormapElements/mesas/mesa9Rosada.png';
import mesa10Rosada from '../../images/floormapElements/mesas/mesa10Rosada.png';
import mesa11Rosada from '../../images/floormapElements/mesas/mesa11Rosada.png';
import mesa12Rosada from '../../images/floormapElements/mesas/mesa12Rosada.png';
import mesa13Rosada from '../../images/floormapElements/mesas/mesa13Rosada.png';

import mesa1Rojo from '../../images/floormapElements/mesas/mesa1Rojo.png';
import mesa2Rojo from '../../images/floormapElements/mesas/mesa2Rojo.png';
import mesa3Rojo from '../../images/floormapElements/mesas/mesa3Rojo.png';
import mesa4Rojo from '../../images/floormapElements/mesas/mesa4Rojo.png';
import mesa5Rojo from '../../images/floormapElements/mesas/mesa5Rojo.png';
import mesa6Rojo from '../../images/floormapElements/mesas/mesa6Rojo.png';
import mesa7Rojo from '../../images/floormapElements/mesas/mesa7Rojo.png';
import mesa8Rojo from '../../images/floormapElements/mesas/mesa8Rojo.png';
import mesa9Rojo from '../../images/floormapElements/mesas/mesa9Rojo.png';
import mesa10Rojo from '../../images/floormapElements/mesas/mesa10Rojo.png';
import mesa11Rojo from '../../images/floormapElements/mesas/mesa11Rojo.png';
import mesa12Rojo from '../../images/floormapElements/mesas/mesa12Rojo.png';
import mesa13Rojo from '../../images/floormapElements/mesas/mesa13Rojo.png';

import mesa1Azul from '../../images/floormapElements/mesas/mesa1Azul.png';
import mesa2Azul from '../../images/floormapElements/mesas/mesa2Azul.png';
import mesa3Azul from '../../images/floormapElements/mesas/mesa3Azul.png';
import mesa4Azul from '../../images/floormapElements/mesas/mesa4Azul.png';
import mesa5Azul from '../../images/floormapElements/mesas/mesa5Azul.png';
import mesa6Azul from '../../images/floormapElements/mesas/mesa6Azul.png';
import mesa7Azul from '../../images/floormapElements/mesas/mesa7Azul.png';
import mesa8Azul from '../../images/floormapElements/mesas/mesa8Azul.png';
import mesa9Azul from '../../images/floormapElements/mesas/mesa9Azul.png';
import mesa10Azul from '../../images/floormapElements/mesas/mesa10Azul.png';
import mesa11Azul from '../../images/floormapElements/mesas/mesa11Azul.png';
import mesa12Azul from '../../images/floormapElements/mesas/mesa12Azul.png';
import mesa13Azul from '../../images/floormapElements/mesas/mesa13Azul.png';

import mesa1ColorDual from '../../images/floormapElements/mesas/mesa1ColorDual.png';
import mesa2ColorDual from '../../images/floormapElements/mesas/mesa2ColorDual.png';
import mesa3ColorDual from '../../images/floormapElements/mesas/mesa3ColorDual.png';
import mesa4ColorDual from '../../images/floormapElements/mesas/mesa4ColorDual.png';
import mesa5ColorDual from '../../images/floormapElements/mesas/mesa5ColorDual.png';
import mesa6ColorDual from '../../images/floormapElements/mesas/mesa6ColorDual.png';
import mesa7ColorDual from '../../images/floormapElements/mesas/mesa7ColorDual.png';
import mesa8ColorDual from '../../images/floormapElements/mesas/mesa8ColorDual.png';
import mesa9ColorDual from '../../images/floormapElements/mesas/mesa9ColorDual.png';
import mesa10ColorDual from '../../images/floormapElements/mesas/mesa10ColorDual.png';
import mesa11ColorDual from '../../images/floormapElements/mesas/mesa11ColorDual.png';
import mesa12ColorDual from '../../images/floormapElements/mesas/mesa12ColorDual.png';
import mesa13ColorDual from '../../images/floormapElements/mesas/mesa13ColorDual.png';

//Paredes
import pared1 from '../../images/floormapElements/paredes/pared1.png';
import pared2 from '../../images/floormapElements/paredes/pared2.png';
import pared3 from '../../images/floormapElements/paredes/pared3.png';
import pared4 from '../../images/floormapElements/paredes/pared4.png';
import pared5 from '../../images/floormapElements/paredes/pared5.png';
import pared6 from '../../images/floormapElements/paredes/pared6.png';
import pared7 from '../../images/floormapElements/paredes/pared7.png';
import pared8 from '../../images/floormapElements/paredes/pared8.png';
import pared9 from '../../images/floormapElements/paredes/pared9.png';
import pared10 from '../../images/floormapElements/paredes/pared10.png';
import pared11 from '../../images/floormapElements/paredes/pared11.png';
import pared12 from '../../images/floormapElements/paredes/pared12.png';
//Decoraciones
import deco1 from '../../images/floormapElements/decoraciones/deco1.png';
import deco2 from '../../images/floormapElements/decoraciones/deco2.png';
import deco3 from '../../images/floormapElements/decoraciones/deco3.png';
import deco4 from '../../images/floormapElements/decoraciones/deco4.png';
import deco5 from '../../images/floormapElements/decoraciones/deco5.png';
import deco6 from '../../images/floormapElements/decoraciones/deco6.png';
//Zonas verdes
import verde1 from '../../images/floormapElements/zonasVerdes/plant1.png';
import verde2 from '../../images/floormapElements/zonasVerdes/plant2.png';
import verde3 from '../../images/floormapElements/zonasVerdes/plant3.png';
import verde4 from '../../images/floormapElements/zonasVerdes/plant4.png';
import verde5 from '../../images/floormapElements/zonasVerdes/plant5.png';
import verde6 from '../../images/floormapElements/zonasVerdes/plant6.png';
//Otros
import otro1 from '../../images/floormapElements/otros/otro1.png';
import otro2 from '../../images/floormapElements/otros/otro2.png';
import otro3 from '../../images/floormapElements/otros/otro3.png';
import otro4 from '../../images/floormapElements/otros/otro4.png';
import otro5 from '../../images/floormapElements/otros/otro5.png';
//Formas
import forma1 from '../../images/floormapElements/formas/forma1.png';
import forma2 from '../../images/floormapElements/formas/forma2.png';
import forma3 from '../../images/floormapElements/formas/forma3.png';
import forma4 from '../../images/floormapElements/formas/forma4.png';

//Botones
import reloj from '../../images/floormapElements/botonesAndOpciones/reloj.png';
import rehacer from '../../images/floormapElements/botonesAndOpciones/005-rehacer.png';
import zoomMas from '../../images/floormapElements/botonesAndOpciones/002-zoom.png';
import zoomMenos from '../../images/floormapElements/botonesAndOpciones/003-zoom-1.png';
import reajustar from '../../images/floormapElements/botonesAndOpciones/001-pantalla-completa.png';
import texto from '../../images/floormapElements/botonesAndOpciones/texto.png';
import mano from '../../images/floormapElements/botonesAndOpciones/Screensh.png';
import mano2 from '../../images/floormapElements/botonesAndOpciones/Screensh2.png';


import {
  Button,
  Box,
  AccordionSummary,
  AccordionDetails,
  Select,
  MenuItem,
  Accordion,
  Container,
  Grid,
  TextField,
  CircularProgress,
  Hidden,
  FormControl,
  FormLabel,
  FormGroup,
  FormControlLabel,
  NativeSelect,
  Checkbox,
  InputLabel,
  Typography,
  Modal
} from '@material-ui/core';
import FloormapItemMenu from '../FloormapItemMenu';
import FloormapShapeMenu from '../FloormapShapeMenu';
import FloormapTextMenu from '../FloormapTextMenu ';
import FloormapDatePicker from '../FloormapDatePicker';
import prepareReservationsTabs from '../../utils/prepareReservationsTabs';
import isTruthy from '../../utils/isTruthy';
import prepareReservationsTable from '../../utils/prepareReservationsTable';
import SpinnerLoader from '../SpinnerLoader';



const FloormapGestionReservaciones = () => {

  let arrayEstados = [];

  const [reload, setReload] = useState(false);

  const [totalDialogData, setTotalDialogData] = useState({ total: 0, walkin: 0, asistido: 0, cancelado: 0 });
  const [totalDialogData2, setTotalDialogData2] = useState({ total: 0, walkin: 0, asistido: 0, cancelado: 0 });

  const [states, setStates] = useState({
    loading: true,
    error: null,
    reservations: [],
    pardepanBlocked: [],
    reservationsBlocked: { reservations: [], cancelled: [] },
    capacity: [],
    paginator: {},
    currentFilter: 'Reservas Confirmadas',
    reservationsVisible: true,
  });

  const [loading, setLoading] = useState(true);

  

  const [exportDataDay, setExportDataDay] = useState([]);
  const [exportDataNight, setExportDataNight] = useState([]);
  const [showingWaitingList, setShowingWaitingList] = useState(false);
  const [showingDeletedList, setShowingDeletedList] = useState(false);
  const [FilterStatusDialog, setDialogFilterStatus] = useState(false);

  const [pending, setPending] = useState(0);
  const [automatic, setAutomatic] = useState(0);
  const [filterdReservations, setFilteredReservations] = useState(states.reservations);
  const [filterTimeOption, setFilterTimeOption] = useState(0);
  const [filteredReservationsForTime, setFilteredReservationsForTime] = useState(filterdReservations);
  const [nameForFilter, setNameForFilter] = useState('');
  const [nombreFiltro, setNombreFiltro] = useState('');
  const [nombreFiltroActivo, setNombreFiltroActivo] = useState(false);
  const [selectedDate, setSelectedDate] = useState(moment(new Date(), 'YYYY-MM-DD').locale('es').format('dddd, D [de] MMM'));
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [limiteInferiorReservas, setLimiteInferiorReservas] = useState(1);
  const [limiteSuperiorReservas, setLimiteSuperiorReservas] = useState(4);
  const [limiteReservas, setLimiteReservas] = useState(0);
  const [horaFiltro, setHoraFiltro] = useState("00:00");

  const [mesasVarias, setMesasVarias] = useState([]);




  //Estado de recarga

  const [floors, setFloors] = useState([]);

  const [floorActual, setFloorActual] = useState(-1);

  const [zonaActual, setZonaActual] = useState("");

  const resizeScreen = () => {
    if(window.screen.width >= 1360){
      return 1;
    }else if(window.screen.width > 720){
      return 0.8;
    }else{
      return 0.4;
    }
  }

  const [sizeScreen, setSizeScreen] = useState(resizeScreen());

  let banderaResponsive = -1;



  matchMedia("(max-width: 720px)").addEventListener('change', (e) => {
    if (e.matches) {
      if(banderaResponsive != 1){
        banderaResponsive = 1;
        // The viewport is >= 1000px wide.
        let s = stateScaleActual / sizeScreen;
        setSizeScreen(resizeScreen());
        setStateScaleActual(s * resizeScreen());
        setState({
          stageScale: s * resizeScreen()
        });
      }
    }
  });

  matchMedia("(min-width: 720px) and (max-width: 1360px)").addEventListener('change', (e) => {
    if (e.matches) {
      if(banderaResponsive != 2){
        banderaResponsive = 2;
        // The viewport is >= 1000px wide.
        let s = stateScaleActual / sizeScreen;
        setSizeScreen(resizeScreen());
        setStateScaleActual(s * resizeScreen());
        setState({
          stageScale: s * resizeScreen()
        });
      }
    }
  });

  matchMedia("(min-width: 1360px)").addEventListener('change', (e) => {
    if (e.matches) {
      if(banderaResponsive != 3){
        banderaResponsive = 3;
        // The viewport is >= 1000px wide.
        let s = stateScaleActual / sizeScreen;
        setSizeScreen(resizeScreen());
        setStateScaleActual(s * resizeScreen());
        setState({
          stageScale: s * resizeScreen()
        });
      }
    }
  });

  const currentCallConfig = () => {
    const retrieveReservationsConfig = localStorage.getItem('ReservationsConfig');
    const retrieveConfigDate = localStorage.getItem('ReservationsConfigDate');
    if (retrieveConfigDate === null) { localStorage.setItem('ReservationsConfigDate', moment().format('YYYY-MM-DD hh:mm a')); }
    const retrieveConfigZone = localStorage.getItem('ReservationsConfigZone');
    const retrieveConfigSortCategory = localStorage.getItem('ReservationsConfigSortCategory');
    const retrieveConfigSortDirection = localStorage.getItem('ReservationsConfigSortDirection');
    const config = {
      lastPage: retrieveReservationsConfig !== null
        ? JSON.parse(retrieveReservationsConfig).lastPage : 1,
      currentPage: retrieveReservationsConfig !== null
        ? JSON.parse(retrieveReservationsConfig).currentPage : 1,
      perPage: retrieveReservationsConfig !== null
        ? Number(JSON.parse(retrieveReservationsConfig).perPage)
        : 100,
      total: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).total : 0,
      from: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).from : 1,
      to: retrieveReservationsConfig !== null ? JSON.parse(retrieveReservationsConfig).to : 5,
      options: [5, 25, 100],
      date: retrieveConfigDate !== null ? retrieveConfigDate.substring(0, 10) : moment(new Date(), 'YYYY-MM-DD').format('YYYY-MM-DD'),
      time: retrieveConfigDate !== null ? retrieveConfigDate.substring(11, retrieveConfigDate.length) : moment(new Date(), 'hh:mm a').format('hh:mm a'),
      zone: retrieveConfigZone !== null ? retrieveConfigZone : 'all',
      sortCategory: retrieveConfigSortCategory !== null ? retrieveConfigSortCategory : 'fecha',
      sortDirection: retrieveConfigSortDirection !== null ? retrieveConfigSortDirection : 'asc',
      search: '',
    };
    return config;
  };

  const [apiCallConfig, setApiCallConfig] = useState(currentCallConfig());
  const [pagination] = useState(apiCallConfig);
  const [catalogReservationStatus, setCatalogReservationStatus] = useState({});

  useEffect(() => {

    const array = filterdReservations.filter((item) => {
      if(filterTimeOption == 0){
        if(nombreFiltroActivo){
          let nombre = item.name;
          if(nombre == null){
            nombre = "";
          }

          let telefono = item.phone;
          if(telefono == null){
            telefono = "";
          }

          if(nombre.includes(nombreFiltro) || telefono.includes(nombreFiltro)){
            return true;
          }
        }else{
          return true;
        }
      }
  
      if(filterTimeOption == 1){
        
        if(moment(item.time, "h:mm A").format("HH:mm") >= "02:00" && moment(item.time, "h:mm A").format("HH:mm") < "11:00"){
          if(nombreFiltroActivo){
            let nombre = item.name;
            if(nombre == null){
              nombre = "";
            }

            let telefono = item.phone;
            if(telefono == null){
              telefono = "";
            }

            if(nombre.includes(nombreFiltro) || telefono.includes(nombreFiltro)){
              return true;
            }
          }else{
            return true;
          }
        }
      }
  
      if(filterTimeOption == 2){
        if(moment(item.time, "h:mm A").format("HH:mm") >= "11:00" && moment(item.time, "h:mm A").format("HH:mm") < "15:00"){
          if(nombreFiltroActivo){
            let nombre = item.name;
            if(nombre == null){
              nombre = "";
            }

            let telefono = item.phone;
            if(telefono == null){
              telefono = "";
            }

            if(nombre.includes(nombreFiltro) || telefono.includes(nombreFiltro)){
              return true;
            }
          }else{
            return true;
          }
        }
      }
  
      if(filterTimeOption == 3){
        if((moment(item.time, "h:mm A").format("HH:mm") >= "15:00" && moment(item.time, "h:mm A").format("HH:mm") < "23:59") || (moment(item.time, "h:mm A").format("HH:mm") >= "00:00" && moment(item.time, "h:mm A").format("HH:mm") < "02:00")){
          if(nombreFiltroActivo){
            let nombre = item.name;
            if(nombre == null){
              nombre = "";
            }

            let telefono = item.phone;
            if(telefono == null){
              telefono = "";
            }

            if(nombre.includes(nombreFiltro) || telefono.includes(nombreFiltro)){
              return true;
            }
          }else{
            return true;
          }
        }
      }

      if(filterTimeOption == 4){
        if(moment(item.time, "h:mm A").format("HH:mm") == horaFiltro){
          if(nombreFiltroActivo){
            let nombre = item.name;
            if(nombre == null){
              nombre = "";
            }

            let telefono = item.phone;
            if(telefono == null){
              telefono = "";
            }

            if(nombre.includes(nombreFiltro) || telefono.includes(nombreFiltro)){
              return true;
            }
          }else{
            return true;
          }
        }
      }

      return false;
    });

    setFilteredReservationsForTime(array);

    setLimiteInferiorReservas(1);
    setLimiteSuperiorReservas(4);
    setLimiteReservas(array.length);

  }, [filterdReservations, filterTimeOption, nombreFiltro])

  useEffect(() => {
    const calatogReservationStatus = localStorage.getItem('catalogReservationStatus');
    const getcatalogs = async () => {
      try {
        const { success, data } = await CatalogsApi
          .catalogReservationStatus();
        console.log(data);
        if (isTruthy(success)) {
          setCatalogReservationStatus(data);
          localStorage.setItem('catalogReservationStatus', JSON.stringify(data));
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
    };
    if (calatogReservationStatus === null || calatogReservationStatus === 'undefined') {
      getcatalogs();
    } else {
      setCatalogReservationStatus(JSON.parse(calatogReservationStatus));
    }
    setLoading(false);
  }, []);

  const [isFilteringByName, setIsFilteringByName] = useState({
    isFiltering: false,
    filterCount: 0,
    currentPage: 1,
  });

  //Estado de selección para el menu
  const [selectedItem, setSelectedItem] = useState(false);

  const formatearTextoZona = (zone) => {
    let z = zone;
    z = z.toLowerCase();

    z = z.replaceAll(" ", "-");
    z = z.replaceAll("á", "a");
    z = z.replaceAll("é", "e");
    z = z.replaceAll("í", "i");
    z = z.replaceAll("ó", "o");
    z = z.replaceAll("ú", "u");
    return z;
  }

  //Settear elementos de un floor

  const setFloorItemsFunc = async () => {

    const imagenes = images;

    let mesas = [];
    let paredes = [];
    let decoraciones = [];
    let verdes = [];
    let otros = [];
    let formas = [];
    let textos = [];

    imagenes.forEach((item) => {

      //Guardar mesas
      if(item.category == "mesa"){
        mesas.push(item);
      }

      //Guardar paredes
      if(item.category == "pared"){
        paredes.push(item);
      }

      //Guardar decoraciones
      if(item.category == "decoracion"){
        decoraciones.push(item);
      }

      //Guardar zonas verdes
      if(item.category == "verde"){
        verdes.push(item);
      }

      //Guardar otros
      if(item.category == "otro"){
        otros.push(item);
      }

      //Guardar formas
      if(item.category == "forma"){
        formas.push(item);
      }

      //Guardar textos
      if(item.category == "texto"){
        textos.push(item);
      }

    });

    const body = {
      "mesas": mesas,
      "paredes": paredes,
      "decoraciones": decoraciones,
      "verdes": verdes,
      "otros": otros,
      "formas": formas,
      "textos": textos
    }

    try{

      setLoading(true);
      
      let { success, data } = await FloormapApi
          .setFloorItems(floorActual, body);
      

      if (isTruthy(success)) {
        console.log("aaa", data);


      }
      
      setLoading(false);

    }catch (error) {
      console.log(error);
      setLoading(false);

    }

  }
  
  const darDimensiones = (categoria, tipo) => {

    const dimensiones = {
      ancho: 0,
      alto: 0,
    }

    if(categoria == "mesa"){
      if(tipo == 1){
        dimensiones.alto = 79.5;
        dimensiones.ancho = 39;
      }

      if(tipo == 2){
        dimensiones.alto = 76.5;
        dimensiones.ancho = 36
      }

      if(tipo == 3){
        dimensiones.alto = 79.5;
        dimensiones.ancho = 54;
      }

      if(tipo == 4){
        dimensiones.alto = 79.5;
        dimensiones.ancho = 78;
      }

      if(tipo == 5){
        dimensiones.ancho = 78;
        dimensiones.alto = 79.5;
      }

      if(tipo == 6){
        dimensiones.ancho = 72;
        dimensiones.alto = 79.5;
      }

      if(tipo == 7){
        dimensiones.ancho = 87;
        dimensiones.alto = 82.5;
      }

      if(tipo == 8){
        dimensiones.ancho = 114;
        dimensiones.alto = 79.5;
      }

      if(tipo == 9){
        dimensiones.ancho = 112.5;
        dimensiones.alto = 114;
      }

      if(tipo == 10){
        dimensiones.ancho = 106.5;
        dimensiones.alto = 79.5;
      }

      if(tipo == 11){
        dimensiones.ancho = 147;
        dimensiones.alto = 79.5;
      }

      if(tipo == 12){
        dimensiones.ancho = 73.5;
        dimensiones.alto = 79.5;
      }

      if(tipo == 13){
        dimensiones.ancho = 94.5;
        dimensiones.alto = 79.5;
      }
    }

    if(categoria == "pared"){
      dimensiones.ancho = 104;
      dimensiones.alto = 130;
    }

    if(categoria == "decoracion"){
      dimensiones.ancho = 120;
      dimensiones.alto = 120;
    }

    if(categoria == "verde"){
      dimensiones.ancho = 120;
      dimensiones.alto = 120;
    }

    if(categoria == "otro"){
      if(tipo == 1){
        dimensiones.alto = 150;
        dimensiones.ancho = 180;
      }

      if(tipo == 2){
        dimensiones.alto = 150;
        dimensiones.ancho = 180;
      }

      if(tipo == 3){
        dimensiones.alto = 150;
        dimensiones.ancho = 180;
      }

      if(tipo == 4){
        dimensiones.alto = 150;
        dimensiones.ancho = 180;
      }

      if(tipo == 5){
        dimensiones.alto = 150;
        dimensiones.ancho = 300;
      }
    }

    if(categoria == "forma"){
      if(tipo == 1){
        dimensiones.alto = 80;
        dimensiones.ancho = 80;
      }

      if(tipo == 2){
        dimensiones.alto = 80;
        dimensiones.ancho = 80;
      }

      if(tipo == 3){
        dimensiones.alto = 80;
        dimensiones.ancho = 80;
      }

      if(tipo == 4){
        dimensiones.alto = 60;
        dimensiones.ancho = 120;
      }
    }

    if(categoria == "forma"){
      dimensiones.alto = 0;
      dimensiones.ancho = 0;
    }

    return dimensiones;
  }

  useEffect(() => {
    if (reload) {
      setReload(false);
    }

    // Obtener el floormap

    const fetchFloormap = async () => {

      try{
        setLoading(true);
        let { success, data } = await FloormapApi
            .getFloormap();
        
        if (isTruthy(success)) {
          console.log(data);

          localStorage.setItem('FloormapId', data.id);
        }
        setLoading(false);
        
      }catch (error) {
        console.log(error);
        setLoading(false);
      }

    }

    fetchFloormap();

    // Obtener pisos del floormap

    const fetchFloors = async () => {

      try{

        setLoading(true);

        const floormapId = localStorage.getItem('FloormapId');
        let { success, data } = await FloormapApi
            .getFloors(floormapId);
        

        if (isTruthy(success)) {

          setFloors(data);

          setFloorActual(data[0].id);

          setZonaActual(formatearTextoZona(data[0].nombreDelPiso));

          localStorage.setItem('ReservationsConfigZone', formatearTextoZona(data[0].nombreDelPiso));

          setApiCallConfig(...apiCallConfig);

          localStorage.setItem('FloorsConfig', JSON.stringify(data));

          console.log(JSON.parse(localStorage.getItem('FloorsConfig')));


        }

        setLoading(false);

        
      }catch (error) {
        console.log(error);
        setLoading(false);

      }

    }

    fetchFloors();

    // Obtener items del floor

    const getFloorItems = async () => {

      try{

        setLoading(true);

        const floorOne = JSON.parse(localStorage.getItem('FloorsConfig'))[0];

        
        let { success, data } = await FloormapApi
            .getFloorItems(floorOne.id);
        
        setStateScaleActual(floorOne.escala * sizeScreen);
    

        setState({
          stageScale: floorOne.escala * sizeScreen
        });
        


        if (isTruthy(success)) {
          console.log("aaa", data);

          let idActual = 0;

          let numMesaActual = 0;
          

          data.forEach((item) => {
            if(item.idNum > idActual){
              idActual = item.idNum;
            }

            if(item.category == "mesa"){
              if(item.numMesa > numMesaActual){
                numMesaActual = item.numMesa;
              }
            }
          });

          idActual++;

          numMesaActual++;

          setId(idActual);

          setNumeroMesa(numMesaActual);

          setImages(data);

          setArrayDeshacer([]);
          setArrayRehacer([]);


        }
        
        setLoading(false);

      }catch (error) {
        console.log(error);
        setLoading(false);
      }

    }

    getFloorItems();
  }, [reload]);

  useEffect(() => {
    
    setSelectedItem(false);

    const fetchReservations = async () => {
      const newConfig = currentCallConfig();
      setSelectedDate(moment(currentCallConfig().date).locale('es').format('dddd, D [de] MMM'));
  
      if (isFilteringByName.isFiltering && isFilteringByName.filterCount === 0) {
        newConfig.search = nameForFilter;
        newConfig.currentPage = isFilteringByName.currentPage;
      }
      if (isFilteringByName.isFiltering && isFilteringByName.filterCount > 0) {
        newConfig.search = nameForFilter;
      }
      try {
        //const resp = await ReservationsApi.getTotalReservationsPerMonth(moment(currentCallConfig().date).format('YYYY'), moment(currentCallConfig().date).format('MM'));
        //   const aux = await ReservationsApi.getAllDayReservations(newConfig);
        setLoading(true);
        let { success, data } = await ReservationsApi
          .getReservationsFast(newConfig);
        if (isTruthy(success)) {
          setPending(data.total);
          setAutomatic(data.countAutomatic);
          console.log(data);
          await prepareReservationsTable(data.reservations.data).then((tableData) => {
            // setReservations(tableData);
            setFilteredReservations(tableData.filter(reservation => (reservation.status !== "ELIMINADO" && reservation.status !== "LISTA DE ESPERA" && reservation.status !== "RECHAZADO" && reservation.status !== "CANCELADO POR EL CLIENTE") || (reservation.type === "waitinglist" && reservation.status !== "EN ESPERA")));
            console.log("aki");
            const newCallConfig = {
              currentPage: data.currentPage,
              from: data.from,
              perPage: data.perPage,
              lastPage: data.lastPage,
              to: data.to,
              total: data.total,
            };
            setFilterTimeOption(0);
            setLimiteInferiorReservas(1);
            setLimiteSuperiorReservas(4);
            setLimiteReservas(tableData.filter(reservation => (reservation.status !== "ELIMINADO" && reservation.status !== "LISTA DE ESPERA" && reservation.status !== "RECHAZADO" && reservation.status !== "CANCELADO POR EL CLIENTE") || (reservation.type === "waitinglist" && reservation.status !== "EN ESPERA")).length);
            setStates({
              loading: false,
              error: null,
              reservations: tableData,
              pardepanBlocked: (data.pardepanBlocked !== null) ? data.pardepanBlocked : [],
              reservationsBlocked: (data.allDay !== null) ? data.allDay : { reservations: [] },
              reservationsVisible: data.visible,
              capacity: prepareReservationsTabs(data.reservations.capacity),
              paginator: newCallConfig,
              currentFilter: 'Reservas confirmadas'
            });
            //CONTACTO	CATEGORIA	NOMBRE	MESA	ZONA	HORA	ASISTENTES	COMENTARIOS
            // const datareservations = tableData.map(elt => [ (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.name, elt.tableNumber,elt.table,elt.date,elt.time,elt.people,elt.status,elt.comments]);
            //const dataday= datareservations.filter(person => person < 60);
            //setExportDataDay(dataday);
            //console.log(dataday)
            // eslint-disable-next-line no-param-reassign
            const emptydata = [
              [
                { value: "SIN RESERVAS", style: { font: { sz: "24", bold: true } } },
                { value: "", style: { font: { bold: true } } },
                { value: "", style: { font: { bold: true } } },
                { value: "", style: { font: { bold: true } } },
                { value: "", style: { font: { bold: true } } },
                { value: "", style: { font: { bold: true } } },
                { value: "", style: { font: { bold: true } } },
                { value: "", style: { font: { bold: true } } },
                { value: "", style: { font: { bold: true } } },
                { value: "", style: { font: { bold: true } } },
              ]
            ];
            const dia = tableData.filter(person => (moment('05:00 pm', 'hh:mm A') >= moment(person.time, 'hh:mm A')));
            const dayreservations = dia.map(elt => [elt.name, elt.tableNumber, elt.table, elt.date, elt.time, elt.people, elt.comments, (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.status]);
            if (dia.length > 0) {
              setExportDataDay(dayreservations);
            } else {
              setExportDataDay(emptydata);
            }
            const noche = tableData.filter(person => (moment('05:00 pm', 'hh:mm A') < moment(person.time, 'hh:mm A')));
            const nightreservations = noche.map(elt => [elt.name, elt.tableNumber, elt.table, elt.date, elt.time, elt.people, elt.comments, (elt.email) ? elt.email : elt.phone, (elt.categories.length > 0) ? elt.categories.map((category) => category.string).join(',') : 'Sin datos', elt.status]);
            if (noche.length > 0) {
              setExportDataNight(nightreservations);
            } else {
              setExportDataNight(emptydata);
            }
  
            const walkin = tableData.filter(person => person.email === 'client@kokonutstudio.com');
  
            const asistido = tableData.filter(person => person.status === 'SE HA IDO' || person.status === 'HA ASISTIDO');
            const totales = tableData.filter(person => person.status === 'SE HA IDO' || person.status === 'HA ASISTIDO');
            const result = asistido.reduce((total, currentValue) => total = total + currentValue.people, 0);
            const result2 = walkin.reduce((total, currentValue) => total = total + currentValue.people, 0);
            const result3 = tableData.length;
            const result4 = walkin.reduce((total, currentValue) => total = total + 1, 0);
            setTotalDialogData({
              total: result,
              walkin: result2,
              asistido: result,
            });
            setTotalDialogData2({
              total: result3,
              walkin: result4,
              asistido: result3,
            });
            localStorage.setItem('ReservationsConfig', JSON.stringify(newCallConfig));
          });
  
        } else {
          setExportDataDay([]);
          setExportDataNight([]);
          setStates({
            loading: false, error: data.error, reservationsVisible: true, reservationsBlocked: { reservations: [], cancelled: [] }, reservations: [], pardepanBlocked: [], capacity: [], paginator: {}
          });
        }
        setLoading(false);
        
      } catch (error) {
        setLoading(false);

        setExportDataDay([]);
        setExportDataNight([]);
        setStates({
          loading: true, error: error.message, reservationsVisible: true, reservationsBlocked: { reservations: [], cancelled: [] }, reservations: [], pardepanBlocked: [], capacity: [], paginator: {}
        });
        console.log('====================================');
        console.log(error.message);
        console.log('====================================');
      }
    };

    //
  
    fetchReservations();
  }, [apiCallConfig, reload]);

  useEffect(() => {

    if(selectedItem){

      let arraySeleccionados = [];

      images.forEach((item) => {

        if(item.category == "mesa"){
          let esParteDeLaReserva = false;

          item.reservations.forEach((r) =>{
            if(esParteDeLaReserva){
              return;
            }

            if(r.encryptId == imageNode.id){
              esParteDeLaReserva = true;
            }
          });

          if(esParteDeLaReserva){
            arraySeleccionados.push(item.idNum);
          }

        }

      });

      setMesasSelecionadas(arraySeleccionados);

    }else{
      setMesasSelecionadas([]);
    }

  }, [selectedItem])


  const emparejarMesaReservacion = async (idnum, reservationId) => {

    try{
      setLoading(true);
      let { success, data } = await FloormapApi
          .setMesaReserva(idnum, reservationId);
      
      if (isTruthy(success)) {
        console.log(data);
        cambiarPiso(floorActual);
      }
      setLoading(false);
      
    }catch (error) {
      setLoading(false);
      console.log(error);
    }

  }

  const obtenerMesas = async (reservationId) => {

    try{
      setLoading(true);

      let { success, data } = await FloormapApi
          .getMesasForReservation(reservationId);
      
      if (isTruthy(success)) {
        console.log(data);
        setMesasOfReserva(data);
      }
      setLoading(false);
      
    }catch (error) {
      console.log(error);
      setLoading(false);
    }

  }

  const emparejarVariasMesasReservacion = async (body) => {

    try{
      setLoading(true);
      let { success, data } = await FloormapApi
          .setVariasMesasReserva(body);
      
      if (isTruthy(success)) {
        console.log(data);
        cambiarPiso(floorActual);
      }
      setLoading(false);
      setMesasVarias([]);
      
    }catch (error) {
      setLoading(false);
      setMesasVarias([]);
      console.log(error);
    }

  }

  // Cambio de piso

  const cambiarPiso = async (id) => {

    // Cambiarpiso
    setLoading(true);

    if(id != -1){
      setFloorActual(id);
      
      let { success, data } = await FloormapApi
            .getFloorItems(id);
        

        if (isTruthy(success)) {
          console.log("aaa", data);

          let idActual = 0

          data.forEach((item) => {
            if(item.idNum > idActual){
              idActual = item.idNum;
            }
          });

          idActual++;

          setId(idActual);

          setImages(data);

          setArrayDeshacer([]);
          setArrayRehacer([]);


        }
    }
    setLoading(false);
  }

  // Obtener escala

  const obtenerEscala = async (id) => {

    // obtenerEscala

    setLoading(true);

    if(id != -1){
      setFloorActual(id);

      let { success, data } = await FloormapApi
            .getScaleFloor(id);

      if (isTruthy(success)) {
        setStateScaleActual(data * sizeScreen);

        setState({
          stageScale: data * sizeScreen
        });
        
      }
    }
    setLoading(false);
  }

  const URLImage = ({ image, onBasicClick, limitarDrag, renovarCordenadas }) => {
  

    const drawHitFromCache = (img, invertir = false) => {

      //Usar solo lo que se renderize
      if(img){
        if(img.attrs.height != 0){
          img.cache();
          if(invertir){
            img.filters([Konva.Filters.Invert]);
          }
          img.drawHitFromCache();
        }
      }
    };
  

    //Texto

    if(image.category == "texto"){

      return (
        <Text
          
          fontSize={image.fontSize}
          fill={image.fill}
          x={image.x}
          y={image.y}
          categoria={image.category}
          idNum={image.idNum}
          onDragMove={ (e) => {limitarDrag(e,2)}}
          onDragEnd = {(e) => {renovarCordenadas(e)}}
          text={image.text}
        />
      );
    }

    //Mesa

    if(image.category == "mesa"){

      //Verificar si la mesa está bloqueada o no
      if(image.blocked){

        const mapeo = Object.freeze({
          "1": mesa1Rosada,
          "2": mesa2Rosada,
          "3": mesa3Rosada,
          "4": mesa4Rosada,
          "5": mesa5Rosada,
          "6": mesa6Rosada,
          "7": mesa7Rosada,
          "8": mesa8Rosada,
          "9": mesa9Rosada,
          "10": mesa10Rosada,
          "11": mesa11Rosada,
          "12": mesa12Rosada,
          "13": mesa13Rosada,
        });
  
        const [img] = useImage(mapeo[image.type]);
  
        return (
          <Group
            
            visibilidad={image.visibilidad}
            tipoSilla={image.tipoSilla}
            blocked={image.blocked}
            personaMinimas={image.personaMinimas}
            personaMaximas={image.personaMaximas}
            numMesa={image.numMesa}
            x={image.x}
            y={image.y}
            width={image.ancho}
            height={image.largo}
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
            image={img}
          >
  
            <Image
              ref={(node) => {drawHitFromCache(node)}}
              image={img}
              width={image.ancho}
              height={image.largo}
              // I will use offset to set origin to the center of the image
              offsetX={img ? img.width / 2 : 0}
              offsetY={img ? img.height / 2 : 0}
            />
            <Text 
              ref={(node) => {drawHitFromCache(node)}}
              text={image.numMesa} 
              fill="#000000" 
              wrap="char" 
              align="center"
              verticalAlign="middle"
              fontSize={20}
              width={image.ancho}
              height={image.largo}
              offsetX={img ? img.width / 2 : 0}
              offsetY={img ? img.height / 2 : 0}
            />
          </Group>
        );


      }else if(mesasSelecionadas.includes(image.idNum)){
        
        const mapeo = Object.freeze({
          "1": mesa1,
          "2": mesa2,
          "3": mesa3,
          "4": mesa4,
          "5": mesa5,
          "6": mesa6,
          "7": mesa7,
          "8": mesa8,
          "9": mesa9,
          "10": mesa10,
          "11": mesa11,
          "12": mesa12,
          "13": mesa13,
        });
  
        const [img] = useImage(mapeo[image.type]);

        // Revisar reservaciones para pintar la mesa
        let numeroDeReservas = 0;
        let arrayReservas = []

        // Conseguir la fecha actual
        let fechaActual = localStorage.getItem('ReservationsConfigDate');

        if(fechaActual != null){
          fechaActual = fechaActual.substring(0, 10);
        }else{
          localStorage.setItem('ReservationsConfigDate', moment().format('YYYY-MM-DD hh:mm a'));
          fechaActual = moment().format('YYYY-MM-DD hh:mm a');
          fechaActual = fechaActual.substring(0, 10);
        }

        console.log(image.reservations);

        // Revisar los objetos
        image.reservations.forEach((item) => {

          const fecha = moment(item.reservationDate).format('YYYY-MM-DD');
          const hora = moment(item.reservationDate).format('HH:mm');

          if(fecha == fechaActual){
            console.log(formatearTextoZona(item.zonaName));
            
            if(formatearTextoZona(item.zonaName) == zonaActual){
  
              if(filterTimeOption == 0){
                numeroDeReservas++;
                arrayReservas.push(item);
              }
  
              if(filterTimeOption == 1){
                if(hora >= "02:00" && hora < "11:00"){
                  numeroDeReservas++;
                  arrayReservas.push(item);

                }
              }
  
              if(filterTimeOption == 2){
                if(hora >= "11:00" && hora < "15:00"){
                  numeroDeReservas++;
                  arrayReservas.push(item);
                }
              }
  
              if(filterTimeOption == 3){
                if((hora >= "15:00" && hora < "23:59") || (hora >= "00:00" && hora < "02:00")){
                  numeroDeReservas++;
                  arrayReservas.push(item);
                }
              }
  
              if(filterTimeOption == 4){
                if(hora == horaFiltro){
                  numeroDeReservas++;
                  arrayReservas.push(item);
                }
              }
            }
          }
          

        });
  
        return (
          <Group
            
            visibilidad={image.visibilidad}
            tipoSilla={image.tipoSilla}
            blocked={image.blocked}
            personaMinimas={image.personaMinimas}
            personaMaximas={image.personaMaximas}
            numMesa={image.numMesa}
            x={image.x}
            y={image.y}
            width={image.ancho}
            height={image.largo}
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
            onClick={onBasicClick}
            image={img}
            reservations={arrayReservas}
          >
  
            <Image
              ref={(node) => {drawHitFromCache(node, true)}}
              image={img}
              width={image.ancho}
              height={image.largo}
              // I will use offset to set origin to the center of the image
              offsetX={img ? img.width / 2 : 0}
              offsetY={img ? img.height / 2 : 0}
            />
            <Text 
              ref={(node) => {drawHitFromCache(node, true)}}
              text={image.numMesa} 
              fill="#000000" 
              wrap="char" 
              align="center"
              verticalAlign="middle"
              fontSize={20}
              width={image.ancho}
              height={image.largo}
              offsetX={img ? img.width / 2 : 0}
              offsetY={img ? img.height / 2 : 0}
            />
          </Group>
        );
      }else{
        // Revisar reservaciones para pintar la mesa
        let numeroDeReservas = 0;
        let arrayReservas = []

        // Conseguir la fecha actual
        let fechaActual = localStorage.getItem('ReservationsConfigDate');

        if(fechaActual != null){
          fechaActual = fechaActual.substring(0, 10);
        }else{
          localStorage.setItem('ReservationsConfigDate', moment().format('YYYY-MM-DD hh:mm a'));
          fechaActual = moment().format('YYYY-MM-DD hh:mm a');
          fechaActual = fechaActual.substring(0, 10);
        }

        console.log(image.reservations);

        // Revisar los objetos
        image.reservations.forEach((item) => {

          const fecha = moment(item.reservationDate).format('YYYY-MM-DD');
          const hora = moment(item.reservationDate).format('HH:mm');

          if(fecha == fechaActual){
            console.log(formatearTextoZona(item.zonaName));
            
            if(formatearTextoZona(item.zonaName) == zonaActual){
  
              if(filterTimeOption == 0){
                numeroDeReservas++;
                arrayReservas.push(item);
              }
  
              if(filterTimeOption == 1){
                if(hora >= "02:00" && hora < "11:00"){
                  numeroDeReservas++;
                  arrayReservas.push(item);

                }
              }
  
              if(filterTimeOption == 2){
                if(hora >= "11:00" && hora < "15:00"){
                  numeroDeReservas++;
                  arrayReservas.push(item);
                }
              }
  
              if(filterTimeOption == 3){
                if((hora >= "15:00" && hora < "23:59") || (hora >= "00:00" && hora < "02:00")){
                  numeroDeReservas++;
                  arrayReservas.push(item);
                }
              }
  
              if(filterTimeOption == 4){
                if(hora == horaFiltro){
                  numeroDeReservas++;
                  arrayReservas.push(item);
                }
              }
            }
          }
          

        });

        // Pintar la mesa segun el numero de reservas
        if(numeroDeReservas === 0){
          //Mesa en blanco

          const mapeo = Object.freeze({
            "1": mesa1,
            "2": mesa2,
            "3": mesa3,
            "4": mesa4,
            "5": mesa5,
            "6": mesa6,
            "7": mesa7,
            "8": mesa8,
            "9": mesa9,
            "10": mesa10,
            "11": mesa11,
            "12": mesa12,
            "13": mesa13,
          });
    
          const [img] = useImage(mapeo[image.type]);
    
          return (
            <Group
              
              visibilidad={image.visibilidad}
              tipoSilla={image.tipoSilla}
              blocked={image.blocked}
              personaMinimas={image.personaMinimas}
              personaMaximas={image.personaMaximas}
              numMesa={image.numMesa}
              x={image.x}
              y={image.y}
              width={image.ancho}
              height={image.largo}
              categoria={image.category}
              type={image.type}
              idNum={image.idNum}
              onDragMove={ (e) => {limitarDrag(e,2)}}
              onDragEnd = {(e) => {renovarCordenadas(e)}}
              onClick={onBasicClick}
              identificador={image.id}
              image={img}
              reservations={arrayReservas}
            >
    
              <Image
                ref={(node) => {drawHitFromCache(node)}}
                image={img}
                width={image.ancho}
                height={image.largo}
                // I will use offset to set origin to the center of the image
                offsetX={img ? img.width / 2 : 0}
                offsetY={img ? img.height / 2 : 0}
              />
              <Text 
                ref={(node) => {drawHitFromCache(node)}}
                text={image.numMesa} 
                fill="#000000" 
                wrap="char" 
                align="center"
                verticalAlign="middle"
                fontSize={20}
                width={image.ancho}
                height={image.largo}
                offsetX={img ? img.width / 2 : 0}
                offsetY={img ? img.height / 2 : 0} 
              />
            </Group>
          );
        }else if(numeroDeReservas === 1){
          // Mesa color azul o roja

          const estaSentado = arrayReservas[0].reservationsStatusId == 2;

          // Clasificar

          if(estaSentado){ // Mesa Roja
            const mapeo = Object.freeze({
              "1": mesa1Rojo,
              "2": mesa2Rojo,
              "3": mesa3Rojo,
              "4": mesa4Rojo,
              "5": mesa5Rojo,
              "6": mesa6Rojo,
              "7": mesa7Rojo,
              "8": mesa8Rojo,
              "9": mesa9Rojo,
              "10": mesa10Rojo,
              "11": mesa11Rojo,
              "12": mesa12Rojo,
              "13": mesa13Rojo,
            });
      
            const [img] = useImage(mapeo[image.type]);
      
            return (
              <Group
                
                visibilidad={image.visibilidad}
                tipoSilla={image.tipoSilla}
                blocked={image.blocked}
                personaMinimas={image.personaMinimas}
                personaMaximas={image.personaMaximas}
                numMesa={image.numMesa}
                x={image.x}
                y={image.y}
                width={image.ancho}
                height={image.largo}
                categoria={image.category}
                type={image.type}
                idNum={image.idNum}
                onDragMove={ (e) => {limitarDrag(e,2)}}
                onDragEnd = {(e) => {renovarCordenadas(e)}}
                onClick={onBasicClick}
                identificador={image.id}
                image={img}
                reservations={arrayReservas}
              >
      
                <Image
                  ref={(node) => {drawHitFromCache(node)}}
                  image={img}
                  width={image.ancho}
                  height={image.largo}
                  // I will use offset to set origin to the center of the image
                  offsetX={img ? img.width / 2 : 0}
                  offsetY={img ? img.height / 2 : 0}
                />
                <Text 
                  ref={(node) => {drawHitFromCache(node)}}
                  text={image.numMesa} 
                  fill="#000000" 
                  wrap="char" 
                  align="center"
                  verticalAlign="middle"
                  fontSize={20}
                  width={image.ancho}
                  height={image.largo}
                  offsetX={img ? img.width / 2 : 0}
                  offsetY={img ? img.height / 2 : 0}  
                />
              </Group>
            );
          }else{
            const mapeo = Object.freeze({
              "1": mesa1Azul,
              "2": mesa2Azul,
              "3": mesa3Azul,
              "4": mesa4Azul,
              "5": mesa5Azul,
              "6": mesa6Azul,
              "7": mesa7Azul,
              "8": mesa8Azul,
              "9": mesa9Azul,
              "10": mesa10Azul,
              "11": mesa11Azul,
              "12": mesa12Azul,
              "13": mesa13Azul,
            });
      
            const [img] = useImage(mapeo[image.type]);
      
            return (
              <Group
                
                visibilidad={image.visibilidad}
                tipoSilla={image.tipoSilla}
                blocked={image.blocked}
                personaMinimas={image.personaMinimas}
                personaMaximas={image.personaMaximas}
                numMesa={image.numMesa}
                x={image.x}
                y={image.y}
                width={image.ancho}
                height={image.largo}
                categoria={image.category}
                type={image.type}
                idNum={image.idNum}
                onDragMove={ (e) => {limitarDrag(e,2)}}
                onDragEnd = {(e) => {renovarCordenadas(e)}}
                onClick={onBasicClick}
                identificador={image.id}
                image={img}
                reservations={arrayReservas}
              >
      
                <Image
                  ref={(node) => {drawHitFromCache(node)}}
                  image={img}
                  width={image.ancho}
                  height={image.largo}
                  // I will use offset to set origin to the center of the image
                  offsetX={img ? img.width / 2 : 0}
                  offsetY={img ? img.height / 2 : 0}
                />
                <Text 
                  ref={(node) => {drawHitFromCache(node)}}
                  text={image.numMesa} 
                  fill="#000000" 
                  wrap="char" 
                  align="center"
                  verticalAlign="middle"
                  fontSize={20}
                  width={image.ancho}
                  height={image.largo}
                  offsetX={img ? img.width / 2 : 0}
                  offsetY={img ? img.height / 2 : 0}  
                />
              </Group>
            );
          }
        }else{
          // Mesa color rojo-amarillo

          //Elegir si tiene punto morado o no

          const estaSentado = arrayReservas[0].reservationsStatusId == 2;

          if(estaSentado){

            const mapeo = Object.freeze({
              "1": mesa1ColorDual,
              "2": mesa2ColorDual,
              "3": mesa3ColorDual,
              "4": mesa4ColorDual,
              "5": mesa5ColorDual,
              "6": mesa6ColorDual,
              "7": mesa7ColorDual,
              "8": mesa8ColorDual,
              "9": mesa9ColorDual,
              "10": mesa10ColorDual,
              "11": mesa11ColorDual,
              "12": mesa12ColorDual,
              "13": mesa13ColorDual,
            });
      
            const [img] = useImage(mapeo[image.type]);

            return (
              <Group
                
                visibilidad={image.visibilidad}
                tipoSilla={image.tipoSilla}
                blocked={image.blocked}
                personaMinimas={image.personaMinimas}
                personaMaximas={image.personaMaximas}
                numMesa={image.numMesa}
                x={image.x}
                y={image.y}
                width={image.ancho}
                height={image.largo}
                categoria={image.category}
                type={image.type}
                idNum={image.idNum}
                onDragMove={ (e) => {limitarDrag(e,2)}}
                onDragEnd = {(e) => {renovarCordenadas(e)}}
                onClick={onBasicClick}
                identificador={image.id}
                image={img}
                reservations={arrayReservas}
              >
      
                <Image
                  ref={(node) => {drawHitFromCache(node)}}
                  image={img}
                  width={image.ancho}
                  height={image.largo}
                  // I will use offset to set origin to the center of the image
                  offsetX={img ? img.width / 2 : 0}
                  offsetY={img ? img.height / 2 : 0}
                />
                <Text 
                  ref={(node) => {drawHitFromCache(node)}}
                  text={image.numMesa} 
                  fill="#000000" 
                  wrap="char" 
                  align="center"
                  verticalAlign="middle"
                  fontSize={20}
                  width={image.ancho}
                  height={image.largo}
                  offsetX={img ? img.width / 2 : 0}
                  offsetY={img ? img.height / 2 : 0}  
                />
              </Group>
            );



          }else{

            const mapeo = Object.freeze({
              "1": mesa1ColorDual,
              "2": mesa2ColorDual,
              "3": mesa3ColorDual,
              "4": mesa4ColorDual,
              "5": mesa5ColorDual,
              "6": mesa6ColorDual,
              "7": mesa7ColorDual,
              "8": mesa8ColorDual,
              "9": mesa9ColorDual,
              "10": mesa10ColorDual,
              "11": mesa11ColorDual,
              "12": mesa12ColorDual,
              "13": mesa13ColorDual,
            });
      
            const [img] = useImage(mapeo[image.type]);
      
            if(image.type == 1 || image.type == 2 || image.type == 3 || image.type == 6 || image.type == 10 || image.type == 12 || image.type == 13){
              return (
                <Group
                  
                  visibilidad={image.visibilidad}
                  tipoSilla={image.tipoSilla}
                  blocked={image.blocked}
                  personaMinimas={image.personaMinimas}
                  personaMaximas={image.personaMaximas}
                  numMesa={image.numMesa}
                  x={image.x}
                  y={image.y}
                  width={image.ancho}
                  height={image.largo}
                  categoria={image.category}
                  type={image.type}
                  idNum={image.idNum}
                  onDragMove={ (e) => {limitarDrag(e,2)}}
                  onDragEnd = {(e) => {renovarCordenadas(e)}}
                  onClick={onBasicClick}
                  identificador={image.id}
                  image={img}
                  reservations={arrayReservas}
                >
        
                  <Image
                    ref={(node) => {drawHitFromCache(node)}}
                    image={img}
                    width={image.ancho}
                    height={image.largo}
                    // I will use offset to set origin to the center of the image
                    offsetX={img ? img.width / 2 : 0}
                    offsetY={img ? img.height / 2 : 0}
                  />
                  <Text 
                    ref={(node) => {drawHitFromCache(node)}}
                    text={image.numMesa} 
                    fill="#000000" 
                    wrap="char" 
                    align="center"
                    verticalAlign="middle"
                    fontSize={20}
                    width={image.ancho}
                    height={image.largo}
                    offsetX={img ? img.width / 2 : 0}
                    offsetY={img ? img.height / 2 : 0} 
                  />
                  <Circle
                    ref={(node) => {drawHitFromCache(node)}}
                    align="center"
                    radius={10}
                    stroke="rgba(152,0,194,1)"
                    strokeWidth={0}
                    fill="rgba(152,0,194,1)"
                    // I will use offset to set origin to the center of the image
                    offsetX={img ? (img.width * -1) +5 : 0}
                    offsetY={img ? img.height / 10 : 0} 
                  />
                </Group>
              );
            }else if(image.type == 8 || image.type == 11){
              return (
                <Group
                  
                  visibilidad={image.visibilidad}
                  tipoSilla={image.tipoSilla}
                  blocked={image.blocked}
                  personaMinimas={image.personaMinimas}
                  personaMaximas={image.personaMaximas}
                  numMesa={image.numMesa}
                  x={image.x}
                  y={image.y}
                  width={image.ancho}
                  height={image.largo}
                  categoria={image.category}
                  type={image.type}
                  idNum={image.idNum}
                  onDragMove={ (e) => {limitarDrag(e,2)}}
                  onDragEnd = {(e) => {renovarCordenadas(e)}}
                  onClick={onBasicClick}
                  identificador={image.id}
                  image={img}
                  reservations={arrayReservas}
                >
        
                  <Image
                    ref={(node) => {drawHitFromCache(node)}}
                    image={img}
                    width={image.ancho}
                    height={image.largo}
                    // I will use offset to set origin to the center of the image
                    offsetX={img ? img.width / 2 : 0}
                    offsetY={img ? img.height / 2 : 0}
                  />
                  <Text 
                    ref={(node) => {drawHitFromCache(node)}}
                    text={image.numMesa} 
                    fill="#000000" 
                    wrap="char" 
                    align="center"
                    verticalAlign="middle"
                    fontSize={20}
                    width={image.ancho}
                    height={image.largo}
                    offsetX={img ? img.width / 2 : 0}
                    offsetY={img ? img.height / 2 : 0}  
                  />
                  <Circle
                    ref={(node) => {drawHitFromCache(node)}}
                    align="center"
                    radius={10}
                    stroke="rgba(152,0,194,1)"
                    strokeWidth={0}
                    fill="rgba(152,0,194,1)"
                    // I will use offset to set origin to the center of the image
                    offsetX={img ? (img.width / 1.5) * -1 : 0}
                    offsetY={img ? img.height / 10 : 0} 
                  />
                </Group>
              );
            }else{
              return (
                <Group
                  
                  visibilidad={image.visibilidad}
                  tipoSilla={image.tipoSilla}
                  blocked={image.blocked}
                  personaMinimas={image.personaMinimas}
                  personaMaximas={image.personaMaximas}
                  numMesa={image.numMesa}
                  x={image.x}
                  y={image.y}
                  width={image.ancho}
                  height={image.largo}
                  categoria={image.category}
                  type={image.type}
                  idNum={image.idNum}
                  onDragMove={ (e) => {limitarDrag(e,2)}}
                  onDragEnd = {(e) => {renovarCordenadas(e)}}
                  onClick={onBasicClick}
                  identificador={image.id}
                  image={img}
                  reservations={arrayReservas}
                >
        
                  <Image
                    ref={(node) => {drawHitFromCache(node)}}
                    image={img}
                    width={image.ancho}
                    height={image.largo}
                    // I will use offset to set origin to the center of the image
                    offsetX={img ? img.width / 2 : 0}
                    offsetY={img ? img.height / 2 : 0}
                  />
                  <Text 
                    ref={(node) => {drawHitFromCache(node)}}
                    text={image.numMesa} 
                    fill="#000000" 
                    wrap="char" 
                    align="center"
                    verticalAlign="middle"
                    fontSize={20}
                    width={image.ancho}
                    height={image.largo}
                    offsetX={img ? img.width / 2 : 0}
                    offsetY={img ? img.height / 2 : 0} 
                  />
                  <Circle
                    ref={(node) => {drawHitFromCache(node)}}
                    align="center"
                    radius={10}
                    stroke="rgba(152,0,194,1)"
                    strokeWidth={0}
                    fill="rgba(152,0,194,1)"
                    // I will use offset to set origin to the center of the image
                    offsetX={img ? (img.width / 2) * -1 : 0}
                    offsetY={img ? img.height / 10 : 0} 
                  />
                </Group>
              );
            }

          }

          
          
        }
      }

    };
    
    //Pared

    if(image.category == "pared"){

      const mapeo = Object.freeze({
        "1": pared1,
        "2": pared2,
        "3": pared3,
        "4": pared4,
        "5": pared5,
        "6": pared6,
        "7": pared7,
        "8": pared8,
        "9": pared9,
        "10": pared10,
        "11": pared11,
        "12": pared12
      });

      const [img] = useImage(mapeo[image.type]);
  
      
    
      return (
        <Image
          ref={(node) => {drawHitFromCache(node)}}
          image={img}
          width={image.ancho}
          height={image.largo}
          x={image.x}
          y={image.y}
          categoria={image.category}
          type={image.type}
          idNum={image.idNum}
          onDragMove={ (e) => {limitarDrag(e,2)}}
          onDragEnd = {(e) => {renovarCordenadas(e)}}
          // I will use offset to set origin to the center of the image
          offsetX={img ? img.width / 2 : 0}
          offsetY={img ? img.height / 2 : 0}
        />
      );
    };


    //Decoración

    if(image.category == "decoracion"){
      
      const mapeo = Object.freeze({
        "1": deco1,
        "2": deco2,
        "3": deco3,
        "4": deco4,
        "5": deco5,
        "6": deco6
      });

      const [img] = useImage(mapeo[image.type]);

      
    
      return (
        <Image
          
          ref={(node) => {drawHitFromCache(node)}}
          image={img}
          width={image.ancho}
          height={image.largo}
          x={image.x}
          y={image.y}
          categoria={image.category}
          type={image.type}
          idNum={image.idNum}
          onDragMove={ (e) => {limitarDrag(e,2)}}
          onDragEnd = {(e) => {renovarCordenadas(e)}}
          // I will use offset to set origin to the center of the image
          offsetX={img ? img.width / 2 : 0}
          offsetY={img ? img.height / 2 : 0}
        />
      );
    };

    //Zonas Verdes
    if(image.category == "verde"){

      const mapeo = Object.freeze({
        "1": verde1,
        "2": verde2,
        "3": verde3,
        "4": verde4,
        "5": verde5,
        "6": verde6
      });

      const [img] = useImage(mapeo[image.type]);
      
      
    
      return (
        <Image
          
          ref={(node) => {drawHitFromCache(node)}}
          image={img}
          width={image.ancho}
          height={image.largo}
          x={image.x}
          y={image.y}
          categoria={image.category}
          type={image.type}
          idNum={image.idNum}
          onDragMove={ (e) => {limitarDrag(e,2)}}
          onDragEnd = {(e) => {renovarCordenadas(e)}}
          // I will use offset to set origin to the center of the image
          offsetX={img ? img.width / 2 : 0}
          offsetY={img ? img.height / 2 : 0}
        />
      );
    };

    //Otros objetos
    if(image.category == "otro"){

      const mapeo = Object.freeze({
        "1": otro1,
        "2": otro2,
        "3": otro3,
        "4": otro4,
        "5": otro5
      });

      const [img] = useImage(mapeo[image.type]);
  
      
    
      return (
        <Image
          
          ref={(node) => {drawHitFromCache(node)}}
          image={img}
          width={image.ancho}
          height={image.largo}
          x={image.x}
          y={image.y}
          categoria={image.category}
          type={image.type}
          idNum={image.idNum}
          onDragMove={ (e) => {limitarDrag(e,2)}}
          onDragEnd = {(e) => {renovarCordenadas(e)}}
          // I will use offset to set origin to the center of the image
          offsetX={img ? img.width / 2 : 0}
          offsetY={img ? img.height / 2 : 0}
        />
      );
    };

    if(image.category == "forma"){

      

      //Selecionar forma
      if(image.type == "1"){
        //Cuadrado
        return(
          <Rect
            x={image.x}
            y={image.y}
            width={80}
            height={80}
            stroke={image.stroke}
            fill={image.fill}
            strokeWidth={image.strokeWidth}
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
            // I will use offset to set origin to the center of the image
            offsetX={image ? 80 / 2 : 0}
            offsetY={image ? 80 / 2 : 0}
          />
        )
      }else if(image.type == "2"){
        //Triangulo
        return(
          <Line
            x={image.x}
            y={image.y}
            points={[40, 0, 80, 80, 0, 80]}
            closed
            stroke={image.stroke}
            fill={image.fill}
            strokeWidth={image.strokeWidth}
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
            // I will use offset to set origin to the center of the image
            offsetX={image ? 80 / 2 : 0}
            offsetY={image ? 80 / 2 : 0}
          />
        )
      }else if(image.type == "3"){
        //Circulo
        return(
          <Circle
            x={image.x}
            y={image.y}
            radius={40}
            stroke={image.stroke}
            fill={image.fill}
            strokeWidth={image.strokeWidth}
            // fill="rgba(255,255,0,1)"
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
            // I will use offset to set origin to the center of the image
            offsetX={image ? 40 / 20 : 0}
            offsetY={image ? 40 / 20 : 0}
          />
        )
      }else if(image.type == "4"){
        //Rectangulo
        return(
          <Rect
            x={image.x}
            y={image.y}
            width={120}
            height={60}
            stroke={image.stroke}
            fill={image.fill}
            strokeWidth={image.strokeWidth}
            categoria={image.category}
            type={image.type}
            idNum={image.idNum}
            onDragMove={ (e) => {limitarDrag(e,2)}}
            onDragEnd = {(e) => {renovarCordenadas(e)}}
            // I will use offset to set origin to the center of the image
            offsetX={image ? 120 / 2 : 0}
            offsetY={image ? 60 / 3 : 0}
          />
        )
      }
  
    };


  }

    
  
  //Variables para monitoriar botones de zoom
  const [estadoBotonMasZoom, setEstadoBotonMasZoom] = useState(true);
  const [estadoBotonMenosZoom, setEstadoBotonMenosZoom] = useState(false);
  
  //las escalas seran 1, 0.8 y 0.5
  const [stateScaleActual, setStateScaleActual] = useState(1 * sizeScreen)

  //Zona escala del stage
  const [state, setState] = useState({
    stageScale: 1 * sizeScreen
  });

  const zoomFunc = (e, opc) => {
    e.preventDefault();

    const scaleBy = 1.10;
    const stage = stageRef.current;
    const oldScale = state.stageScale;
    const mousePointTo = {
      x: 400 / oldScale - stage.x() / oldScale,
      y: 200 / oldScale - stage.y() / oldScale
    };

    let newScale;

    switch(opc){
      case 1:
        newScale = oldScale * scaleBy
        break;
      case 2:
        newScale = oldScale / scaleBy
        break;
      case 0:
        newScale = stateScaleActual
        break;
    }

    //Seguro para evitar escalar mal
    if(newScale > stateScaleActual * scaleBy * scaleBy * scaleBy){
      return;
    }

    if(newScale < stateScaleActual){
      newScale = stateScaleActual
    }

    if(state.stageScale >= stateScaleActual && newScale > stateScaleActual){
      stage.draggable(true);
      setState({
        stageScale: newScale
      });
      stageRef.current.setAbsolutePosition({
        x: -(mousePointTo.x - 400 / newScale) * newScale, 
        y: -(mousePointTo.y - 200 / newScale) * newScale
      });
    }else{
      stage.draggable(false);
      setState({
        stageScale: newScale
      });
      stageRef.current.setAbsolutePosition({x: 0, y: 0})
    }

    if(newScale == stateScaleActual * scaleBy * scaleBy * scaleBy){
      setEstadoBotonMasZoom(false);
    }else{
      setEstadoBotonMasZoom(true);
    }

    if(newScale == stateScaleActual){
      setEstadoBotonMenosZoom(false);
    }else{
      setEstadoBotonMenosZoom(true);
    }
  };

  //imagenes drag

  const dragUrl = React.useRef();
  const stageRef = React.useRef();
  const categoryOfElement = React.useRef();
  const typeOfElement = React.useRef();

  const [mesaPrincipal, setMesaPrincipal] = useState();


  //Modal texto mesa ocupada
  const [modalMesaOcupada, setModalMesaOcupada] = useState(false);

  const cerrarModalMesaOcupada = () => {
    setModalMesaOcupada(false);
  }

  //Modal texto no se pudo reservar
  const [modalMesasReservacionError, setModalMesasReservacionError] = useState(false);

  const cerrarModalMesasReservacionError = () => {
    setModalMesasReservacionError(false);
    setMesasVarias([]);
  }

  //Modal texto no se pudo reservar
  const [modalMesasReservacionErrorOrden, setModalMesasReservacionErrorOrden] = useState(false);

  const cerrarModalMesasReservacionErrorOrden = () => {
    setModalMesasReservacionErrorOrden(false);
    setMesasVarias([]);
  }

  //Texto y modal
  const [modalMesasReservacion, setModalMesasReservacion] = useState(false);

  const abrirModalMesasReservacion = (id) => {

    let personas = 0;

    let mesasAEntregar = [];

    let mesaPrinc;

    let mesas = images.filter((item) => {

      if(item.category != "mesa"){
        return false;
      }else{
        //Sacar la mesa principal
        if(item.id == id){
          personas = item.personaMaximas;
          mesasAEntregar.push(item);
          setMesaPrincipal(item);
          mesaPrinc = item;
          return false;
        }else{
          let mesaNoOcupada = true;

          item.reservations.forEach((reservation) => {
            if(reservation.reservationDate == imageNode.dateRaw){
              mesaNoOcupada = false;
            }
          })

          return mesaNoOcupada;
        }
      }      
    });

    mesas = mesas.sort((a, b) => a.personaMaximas - b.personaMaximas);

    mesas.forEach((mesa) => {
      if(personas < imageNode.people){
        personas = mesa.personaMaximas + personas
        mesasAEntregar.push(mesa)
      }
    })

    if(personas >= imageNode.people){
      //Verificar que se puedan reorganizar las mesas
      let arregloOriginal = JSON.parse(JSON.stringify(images));

      let sePuedeReorganizar = true;

      mesasAEntregar.forEach((m) =>{

        if(!sePuedeReorganizar){
          return;
        }
        
        if(!reorganizarMesas(mesaPrinc, m)){
          sePuedeReorganizar = false;
        }
      })

      if(sePuedeReorganizar){
        //Activar modal de multiples mesas
        setArrayOriginal(arregloOriginal);
        setMesasVarias(mesasAEntregar);
        setModalMesasReservacion(true);
      }else{
        //Restaurar arreglo y dar mensaje
        setImages(arregloOriginal)
        setModalMesasReservacionErrorOrden(true);
        setTimeout(() => {
          setModalMesasReservacionErrorOrden(false);
        }, 2500);
        setModalMesasReservacion(false);
      }
    }else{
      //Dar un mensaje de que no se puede hacer la reserva a traves de un modal
      setModalMesasReservacionError(true);
      setTimeout(() => {
        setModalMesasReservacionError(false);
      }, 2000);
      setModalMesasReservacion(false);
     

    }
  }

  const cerrarModalMesasReservacion = () => {
    setModalMesasReservacion(false);
    setMesasVarias([]);
    setImages(arrayOriginal);
  }

  const reservarMesas = () =>{
    
    setFloorItemsFunc();

    emparejarVariasMesasReservacion(
      {
        mesas: mesasVarias,
        reservation: imageNode,
      }
    );

    setModalMesasReservacion(false);
    setMesasVarias([]);

  }

  const comprobarValidezCoordenada = (nuevoInicioX, nuevoFinX, nuevoInicioY, nuevoFinY, arrayDeCoordenadas) => 
  {
    // inicio
    // finX
    // inicioY
    // finY
    // No tocar, problema de geometria analitica

    let esValido = true;

    arrayDeCoordenadas.forEach((coordenada) => {

      if(!esValido){
        return
      }

      if(nuevoInicioX < 0 || nuevoInicioY < 0){
        esValido = false;
      }else{
        esValido = true;
      }

      if(nuevoFinX > stageRef.current.width() / stateScaleActual || nuevoFinY > stageRef.current.height() / stateScaleActual){
        esValido = false;
      }else{
        esValido = true;
      }

      //Revisar cordenadas x e y 
      if((nuevoFinX < coordenada.inicioX || coordenada.finX < nuevoInicioX) || (nuevoInicioY < coordenada.finY || coordenada.inicioY < nuevoFinY)){
        esValido = true;
      }else{
        esValido = false;
      }

    })

    return esValido;
  }

  const reorganizarMesas = (mesaCentral, mesaSecundaria) => {

    //Iniciar array de cordenadas
    let objetos = [];

    //Obtener coordenadas mesa central
    const coordenadasMesaCentral = {
      inicioX: mesaCentral.x,
      finX: mesaCentral.ancho + mesaCentral.x,
      inicioY: mesaCentral.y,
      finY: mesaCentral.largo + mesaCentral.y,
    }

    //Obtener las dimensiones de la mesa central
    const dimensionesSecundaria = {
      ancho: mesaSecundaria.ancho,
      alto: mesaSecundaria.largo,
    }
    
    // Obtener coordenadas de todos los objetos
    images.forEach((item) => {
      if(item.category != "texto"){
        if(mesaSecundaria.id != item.id){
          objetos.push({
            inicioX: item.x,
            finX: item.ancho + item.x,
            inicioY: item.y,
            finY: item.largo + item.y,
          });
        }
      }
    });

    if(comprobarValidezCoordenada(coordenadasMesaCentral.finX + 10, coordenadasMesaCentral.finX + 10 + dimensionesSecundaria.ancho, coordenadasMesaCentral.inicioY, coordenadasMesaCentral.inicioY + dimensionesSecundaria.alto, objetos))
    {
      //Se comprobo la dirección derecha
      setImages(images.map((img) => {
        if(img.id == mesaSecundaria.id){
          img.x = coordenadasMesaCentral.finX + 10;
          img.y = coordenadasMesaCentral.inicioY;
        }
        return img;
      }));

      return true;
      
    }else if(comprobarValidezCoordenada(coordenadasMesaCentral.inicioX, coordenadasMesaCentral.inicioX + dimensionesSecundaria.ancho, coordenadasMesaCentral.finY + 10, coordenadasMesaCentral.finY + 10 + dimensionesSecundaria.alto, objetos))
    {
      //Se comprobo la dirección de abajo
      setImages(images.map((img) => {
        if(img.id == mesaSecundaria.id){
          img.x = coordenadasMesaCentral.inicioX;
          img.y = coordenadasMesaCentral.finY + 10;
        }
        return img;
      }))

      return true;

    }else if(comprobarValidezCoordenada(coordenadasMesaCentral.finX + 10, coordenadasMesaCentral.finX + 10 + dimensionesSecundaria.ancho, coordenadasMesaCentral.finY + 10, coordenadasMesaCentral.finY + 10 + dimensionesSecundaria.alto, objetos))
    {
      //Se comprobo la dirección derecha inferior
      setImages(images.map((img) => {
        if(img.id == mesaSecundaria.id){
          img.x = coordenadasMesaCentral.finX + 10;
          img.y = coordenadasMesaCentral.finY + 10;
        }
        return img;
      }))

      return true;

    }else if(comprobarValidezCoordenada(coordenadasMesaCentral.inicioX, coordenadasMesaCentral.inicioX + dimensionesSecundaria.ancho, coordenadasMesaCentral.inicioY - 10 - dimensionesSecundaria.alto, coordenadasMesaCentral.inicioY - 10, objetos))
    {
      //Se comprobo la dirección de arriba
      setImages(images.map((img) => {
        if(img.id == mesaSecundaria.id){
          img.x = coordenadasMesaCentral.inicioX;
          img.y = coordenadasMesaCentral.inicioY - 10 - dimensionesSecundaria.alto;
        }
        return img;
      }))

      return true;

    }else if(comprobarValidezCoordenada(coordenadasMesaCentral.finX + 10, coordenadasMesaCentral.finX + 10 + dimensionesSecundaria.ancho, coordenadasMesaCentral.inicioY - 10 - dimensionesSecundaria.alto, coordenadasMesaCentral.inicioY - 10, objetos))
    {
      //Se comprobo la derecha superior
      setImages(images.map((img) => {
        if(img.id == mesaSecundaria.id){
          img.x = coordenadasMesaCentral.finX + 10;
          img.y = coordenadasMesaCentral.inicioY - 10 - dimensionesSecundaria.alto;
        }
        return img;
      }))

      return true;

    }else if(comprobarValidezCoordenada(coordenadasMesaCentral.inicioX - 10 - dimensionesSecundaria.ancho, coordenadasMesaCentral.inicioX - 10, coordenadasMesaCentral.inicioY, coordenadasMesaCentral.inicioY + dimensionesSecundaria.alto, objetos))
    {
      //Se comprobo la izquierda
      setImages(images.map((img) => {
        if(img.id == mesaSecundaria.id){
          img.x = coordenadasMesaCentral.inicioX - 10 - dimensionesSecundaria.ancho;
          img.y = coordenadasMesaCentral.inicioY;
        }
        return img;
      }))

      return true;

    }else if(comprobarValidezCoordenada(coordenadasMesaCentral.inicioX - 10 - dimensionesSecundaria.ancho, coordenadasMesaCentral.inicioX - 10, coordenadasMesaCentral.inicioY - 10 - dimensionesSecundaria.alto, coordenadasMesaCentral.inicioY - 10, objetos))
    {
      //Se comprobo la dirección superior izquierda
      setImages(images.map((img) => {
        if(img.id == mesaSecundaria.id){
          img.x = coordenadasMesaCentral.inicioX - 10 - dimensionesSecundaria.ancho;
          img.y = coordenadasMesaCentral.inicioY - 10 - dimensionesSecundaria.alto;
        }
        return img;
      }))

      return true;

    }else if(comprobarValidezCoordenada(coordenadasMesaCentral.inicioX - 10 - dimensionesSecundaria.ancho, coordenadasMesaCentral.inicioX - 10, coordenadasMesaCentral.finY + 10, coordenadasMesaCentral.finY + 10 + dimensionesSecundaria.alto, objetos))
    {
      //Se comprobo la dirección inferior izquierda
      setImages(images.map((img) => {
        if(img.id == mesaSecundaria.id){
          img.x = coordenadasMesaCentral.inicioX - 10 - dimensionesSecundaria.ancho;
          img.y = coordenadasMesaCentral.finY + 10;
        }
        return img;
      }))

      return true;

    }else{
      return false;
    }

  }



  const openImageDialog = (image) => {
    setImageDialogData(image);
    setImageDialog(true);
  };

  const closeImageDialog = () => {
    setImageDialog(false);
  };
  
  const [imageDialog, setImageDialog] = useState(false);
  const [imageDialogData, setImageDialogData] = useState('');

  const openCommentDialog = () => {
    setCommentDialog(true);
  };

  const closeCommentDialog = () => {
    setCommentDialog(false);
  };
  
  const [commentDialog, setCommentDialog] = useState(false);

  //Arreglo backup
  const [arrayOriginal, setArrayOriginal] = useState([]);

  //Arreglo mesas de una reserva
  const [arrayMesasOfReserva, setMesasOfReserva] = useState([]);

  const [modalMesasOfReserva, setModalMesasOfReserva] = useState(false);

  const cerrarModalMesasOfReserva = () => {
    setModalMesasOfReserva(false);
  }

  //Arreglo de mesas seleccionadas
  const [mesasSelecionadas, setMesasSelecionadas] = useState([]);

  //Arreglo de elementos
  const [images, setImages] = useState([]);

  //Ids
  const [id, setId] = useState(1);

  //Numero de la mesa
  const [numeroMesa, setNumeroMesa] = useState(1);

  

  //Reservación a inspeccionar
  const [imageNode, setImageNode] = useState();

  //Numero de reservación
  const [reservacionNum, setReservacionNum] = useState(-1);

  //Zona canva
  const grid = 20
  const gridWidth = 801 / stateScaleActual

  const linesA = [] // Rayas verticales
  const linesB = [] // Rayas horizontales

    for (let i = 0; i < gridWidth / grid; i++) {
      linesA.push(
        <Line
          strokeWidth={2}
          stroke={'grey'}
          points={[i * grid, 0, i * grid, gridWidth/2]}
        />
      )

      if(i < (gridWidth / grid)/2){
        linesB.push(
          <Line
            strokeWidth={2}
            stroke={'grey'}
            points={[0, i * grid, gridWidth, i * grid]}
          />
        )
      }

    }

  //Fin zona canva


  const asignarId = () => {
    let idAsignada = id;
    setId(id + 1);
    return idAsignada;
  }

  const onBasicClick = (e) => {
    

    e.cancelBubble = true; //prevent event bubbling emparejarVariasMesasReservacion()

    console.log(e.currentTarget);
    

    if(selectedItem){
      // validar si no hay una reservacion en la mesa en la hora actual
      let tieneReserva = false;

      e.currentTarget.attrs.reservations.forEach((item) =>{

        if(item.reservationDate == imageNode.dateRaw){
          tieneReserva = true;
        }
      });
      

      if(!tieneReserva){
        // validar numero de personas y mesas
        if(e.currentTarget.attrs.personaMaximas >= imageNode.people){
          emparejarMesaReservacion(e.currentTarget.attrs.identificador, imageNode.id);
        }else{
          abrirModalMesasReservacion(e.currentTarget.attrs.identificador);
          
        }
      }else{
        //Dar un mensaje de que no se puede hacer la reserva a traves de un modal
        setModalMesaOcupada(true);
        setTimeout(() => {
          setModalMesaOcupada(false);
        }, 2000);
        setModalMesasReservacion(false);
      }

    }else{


      //Seleccionar la primera reservación de la mesa si la tiene Nota: recuerda usar useEffect
      e.currentTarget.attrs.reservations.forEach((reservation) =>{

        if(selectedItem){
          return;
        }

        filteredReservationsForTime.forEach((reservation2) => {
          if(selectedItem){
            return;
          }  

          if(reservation.encryptId == reservation2.id){
            console.log("LLegue");
            setImageNode(reservation2);
            setSelectedItem(true);
          }

        })
      });
    }
  };

  const limitarDrag = (e, opc) => {

    e.cancelBubble = true; //prevent event bubbling 

    console.log(images);

    //const absPos = stageRef.current.getAbsolutePosition();

    const absPos = e.currentTarget.getAbsolutePosition();

    console.log('====================================');
    console.log(absPos);
    console.log('====================================');


    const newAbsPos = {...absPos};


    if(opc == 1){
      // Limitar Eje X
      if(absPos.x < (stageRef.current.width() * stateScaleActual - stageRef.current.width() * state.stageScale) / stateScaleActual){
        newAbsPos.x =   (stageRef.current.width() * stateScaleActual - stageRef.current.width() * state.stageScale) / stateScaleActual;
      }

      if(absPos.x > 0){
        newAbsPos.x =  0;
      }

      //Limitar Eje Y
      if(absPos.y < (stageRef.current.height() * stateScaleActual - stageRef.current.height() * state.stageScale) / stateScaleActual){
        newAbsPos.y = (stageRef.current.height() * stateScaleActual - stageRef.current.height() * state.stageScale) / stateScaleActual;
      }

      if(absPos.y > 0){
        newAbsPos.y =  0;
      }
    }else if(opc == 2){
      //Limitar Eje X
      if(absPos.x > stageRef.current.width() - ((stageRef.current.width() * 0.05))){
        newAbsPos.x =   stageRef.current.width() - ((stageRef.current.width() * 0.05));
      }

      if(absPos.x < 0){
        newAbsPos.x =  0;
      }

      //Limiar Eje Y
      if(absPos.y > stageRef.current.height()  - ((stageRef.current.height() * 0.10) )){
        newAbsPos.y = stageRef.current.height()  - ((stageRef.current.height() * 0.10) );
      }

      if(absPos.y < 0){
        newAbsPos.y =  0;
      }
    }

    if(opc == 1){
      stageRef.current.setAbsolutePosition({x: newAbsPos.x, y: newAbsPos.y});
    }else if(opc == 2){
      e.currentTarget.setAbsolutePosition({x: newAbsPos.x, y: newAbsPos.y});
    }
  }

  //Renovar cordenadas de un objeto
  const renovarCordenadas = (e) =>{

    //Guardar estado
    setArrayDeshacer(arrayDeshacer.concat([
      JSON.parse(JSON.stringify(images))
    ]));
    setArrayRehacer([]);

    const id = e.target.attrs.idNum;

    setImages(images.map((image) => {
      if(id == image.idNum){
        image.x = e.target.attrs.x;
        image.y = e.target.attrs.y;
        if(selectedItem){
          imageNode.attrs.x = e.target.attrs.x;
          imageNode.attrs.y = e.target.attrs.y;
        }
      }
      return image;
    }));
    
  }

  //Rehacer y deshacer
  const [arrayRehacer, setArrayRehacer] = useState([]);  
  const [arrayDeshacer, setArrayDeshacer] = useState([]);    

  const renderClientMenu = () => (
    <>
      <div className='border-menu-accordion mb-2'>
        <div className='border-bottom pt-1 pb-1'>
          <div className='ml-1'>
            <Typography className='bold-font'>Mesa #{reservacionNum < 9 ? `0${reservacionNum}` : reservacionNum}</Typography>
          </div>
        </div>
        <div className='pt-1'>
          <div className='ml-1 dFlex flex-row align-center justify-center mr-1'>
            <button type="button" className="photocontainer" width="100px" onClick={() => openImageDialog((imageNode.photo === null || imageNode.photo === '') ? DefaulImage : imageNode.photo)}>
              {(imageNode.photo === null || imageNode.photo === '') ? (
                <>
                  <img src={DefaulImage} alt="" width="100%" />
                </>
              ) : (
                <>
                  <img src={imageNode.photo} alt="" width="100%" />
                </>
              )}
            </button>
          </div>
          <div className='mt-05 ml-1 dFlex flex-column  justify-between mr-1'>
            <Typography variant='body2' className='bold-font tiny-font3'>Nombre del cliente</Typography>
            <div className='dFlex flex-column align-center justify-between mt-05'>
              <TextField
                className='floormap-item-input w99 tiny-font3'
                type="text"
                inputProps={{defaultValue: imageNode.name, className: "floormap-item-input__value tiny-font3 w99"}}
              />
            </div>
          </div>
          <div className='mt-05 ml-1 dFlex flex-column tiny-font3 justify-between mr-1'>
            <Typography variant='body2' className='tiny-font3 bold-font'>Número de teléfono</Typography>
            <div className='dFlex flex-column align-center justify-between mt-05'>
              <TextField
                className='floormap-item-input w99 tiny-font3'
                type="text"
                inputProps={{defaultValue: imageNode.phone, className: "floormap-item-input__value tiny-font3 w99"}}
              />
            </div>
          </div>
          <div className='ml-1 mt-05 dFlex flex-row align-center justify-between mr-1'>
            <Typography variant='body2' className='bold-font tiny-font3'>Hora de la reserva</Typography>
            <TextField
                className='floormap-item-input tiny-font3 w3-8r'
                type="text"
                inputProps={{defaultValue: moment(imageNode.time, "h:mm A").format("HH:mm"), className: "floormap-item-input__value w3-8r tiny-font3"}}
              />
          </div>
          <div className='ml-1 mt-05 dFlex flex-row align-center justify-between mr-1'>
            <div className='dFlex flex-row align-center justify-between'>
              <Typography variant='body2' className='bold-font tiny-font4 w3r'>Número de personas</Typography>
              <TextField
                className='floormap-item-input input-tiny'
                type="number"
                inputProps={{min: 1, max: 99, defaultValue: imageNode.people, className: "tiny-font4 floormap-item-input__value"}}
              />
            </div>
            <div className='dFlex flex-row align-center justify-between'>
              <Typography variant='body2' className='bold-font tiny-font4 w3r '>Número de visitas</Typography>
              <TextField
                className='floormap-item-input input-tiny'
                type="number"
                inputProps={{min: 1, max: 99, defaultValue: imageNode.visits, className: "tiny-font4 floormap-item-input__value"}}
              />
            </div>
          </div>
          <div className='ml-1 mt-05 dFlex flex-row align-center justify-between mr-1'>
            <Typography variant='body2' className='bold-font tiny-font3'>Status</Typography>
            {(imageNode.status !== 'CANCELADO POR EL CLIENTE')
              ? (
                <div className='tiny-font5'>
                  <MenuReservationStatus
                    id={imageNode.id}
                    status={(imageNode.status === 'EN ESPERA') ? 'ANOTADA POR EL RESTAURANTE' : (imageNode.status === 'LISTA DE ESPERA') ? 'PENDIENTE POR CONTESTAR' : imageNode.status}
                    type={imageNode.type}
                    catalog={catalogReservationStatus}
                    reservationDate={imageNode.dateRaw}
                    setNewStatus={() => {
                      setApiCallConfig({
                        ...pagination,
                      });
                    }}
                  />
                </div>
              ) : (
                <>
                  <span className="canceled">
                    {imageNode.status}
                  </span>
                </>
            )}
          </div>
        </div>
        <div className='border-bottom pt-1 w100 dFlex flex-column align-center justify-center'>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            className="w90 mb-1e"
          
          >
            Guardar
          </Button>
        </div>
      </div>
      
    </>
  );

  const renderDatePicker = () => (
    <FloormapGestionReservacionesContext.Provider value={{ apiCallConfig, setApiCallConfig }}>
      <FloormapDatePicker
        isOpen={isCalendarOpen}
        setIsOpen={setIsCalendarOpen}
        apiCallConfig={apiCallConfig}
        setNewDate={setApiCallConfig}
      />
    </FloormapGestionReservacionesContext.Provider>
  );

  const renderShapeMenu = () => (
    <>
      <FloormapGestionReservacionesContext.Provider value={{ arrayRehacer, setArrayRehacer, arrayDeshacer, setArrayDeshacer, id, setId, images, setImages, selectedItem, setSelectedItem, imageNode, setImageNode }}>
        <FloormapShapeMenu />
      </FloormapGestionReservacionesContext.Provider>
    </>
  );

  const renderTextMenu = () => (
    <>
      <FloormapGestionReservacionesContext.Provider value={{ arrayRehacer, setArrayRehacer, arrayDeshacer, setArrayDeshacer, id, setId, images, setImages, selectedItem, setSelectedItem, imageNode, setImageNode }}>
        <FloormapTextMenu />
      </FloormapGestionReservacionesContext.Provider>
    </>
  );

  function convertRemToPixels(rem) {    
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
  }

  useEffect(() => {
    const tamanoDiv = document.querySelector("#boxSearch")
    const tamanoDivLimite = document.querySelector("#boxLimit")

    console.log(tamanoDiv.getBoundingClientRect());
    setSize({x: tamanoDiv.getBoundingClientRect().width });
    setReferenceSize({x: tamanoDiv.getBoundingClientRect().width });
    setLimitSize({x: tamanoDivLimite.getBoundingClientRect().width + tamanoDiv.getBoundingClientRect().width});

  }, [])

  // Hay que optimizar
  // let timeout = false;
  // let delay = 250;

  // window.addEventListener("resize", () =>{

  //   clearTimeout(timeout);

  //   timeout = setTimeout(() => {
  //     const tamanoDiv = document.querySelector("#boxSearch")
  //     const tamanoDivLimite = document.querySelector("#boxLimit")

  //     setSize({x: tamanoDiv.getBoundingClientRect().width });
  //     setReferenceSize({x: tamanoDiv.getBoundingClientRect().width });
  //     setLimitSize({x: tamanoDivLimite.getBoundingClientRect().width + tamanoDiv.getBoundingClientRect().width});
  //   }, delay);
    
  // });

  const [size, setSize] = useState({ x: 400 });
  const [referenceSize, setReferenceSize] = useState({ x: 500 });
  const [limitSize, setLimitSize] = useState({ x: 1000 });

  const handler = (mouseDownEvent) => {
    const startSize = size;
    const startPosition = { x: mouseDownEvent.pageX};
    
    function onMouseMove(mouseMoveEvent) {
      if(startSize.x - startPosition.x + mouseMoveEvent.pageX <= limitSize.x){
        if(startSize.x - startPosition.x + mouseMoveEvent.pageX > limitSize.x / 1.3){
          setSize(currentSize => ({ 
            x: limitSize.x,
          }));
        }else if(startSize.x - startPosition.x + mouseMoveEvent.pageX <= referenceSize.x){
          setSize(currentSize => ({ 
            x: referenceSize.x,
          }));
        }else{
          setSize(currentSize => ({ 
            x: startSize.x - startPosition.x + mouseMoveEvent.pageX,
          }));
        }
      }
    }
    function onMouseUp() {
      document.body.removeEventListener("mousemove", onMouseMove);
      // uncomment the following line if not using `{ once: true }`
      // document.body.removeEventListener("mouseup", onMouseUp);
    }
    
    document.body.addEventListener("mousemove", onMouseMove);
    document.body.addEventListener("mouseup", onMouseUp, { once: true });
  };

  const preventDragHandler = (e) => {
    e.preventDefault();
  }

  

  const renderImageDialog = () =>{
    return(
      <DialogImage
        isOpen={imageDialog}
        onNo={closeImageDialog}
        data={imageDialogData}
      />
    )
  }

  const renderCommentDialog = () =>{
    return(
      <DialogCommentFloormap
        isOpen={commentDialog}
        onNo={closeCommentDialog}
      />
    )
  }  

  return (
    <>
    {!states.loading && renderDatePicker()}
    {!states.loading && renderImageDialog()}
    {!states.loading && renderCommentDialog()}

    
    <Grid container className='justify-center'>
      <Grid item md={2} >
        <div id='boxSearch' className='border mb-2e w100 search-box'>
          <SearchIcon htmlColor="#ddd"/>
          <TextField
            id="standard-basic"
            className='w100'
            placeholder="Buscar por nombre o teléfono"
            inputProps={{className: "search-input"}}
            onChange={(e) => {

              if(e.target.value.trim().length >= 4){
                const busqueda = e.target.value.trim();
                setNombreFiltro(busqueda);
                setNombreFiltroActivo(true)
              }else{
                if(nombreFiltro != ""){
                  setNombreFiltro("");
                  setNombreFiltroActivo(false)
                }
              }

            }}
            // value={nameForFilter}
          />
          {/* <IconButton
            // onClick={(e) => { handlePopper(e); setNameForFilter(''); }}
            edge="start"
            color="primary"
            aria-label="menu"
            size="small"
          >
            <CloseIcon />
          </IconButton> */}
        </div>

        {/*Primer menu*/}

        <div className={`border-menu-drag mb-2`} style={{width: size.x}}>
          <div className='pl-1 border-menu-drag--header'>
            <div className={`${size.x >= limitSize.x ? "w25" : "" } `}>
              <Typography>Reservaciones</Typography>
              <Typography className='tiny-font2'>por hora de reserva</Typography>
            </div>
            {size.x >= limitSize.x ?
                <>
                  <div className='contacto-section w10'>
                    <Typography>CONTACTO</Typography>
                  </div>

                  <div className='categoria-section w10'>
                    <Typography>CATEGORÍA</Typography>
                  </div>

                  <div className='zona-section w5'>
                    <Typography>ZONA</Typography>
                  </div>

                  <div className='personas-section w5'>
                    <img src={IconPeople} alt="asistentes" className="iconpeople" />
                  </div>

                  <div className='status-section w20'>
                    <Typography>STATUS</Typography>
                  </div>

                  <div className='comentarios-section w10'>
                    <Typography>COMENTARIOS</Typography>
                  </div>
              
                  <div className='visitas-section w10'>
                    <Typography>VISITAS</Typography>
                  </div>
                </> : null
              }

              <IconButton
                variant="outlined"
                className={`${size.x >= limitSize.x ? "w5" : "" } floormap-drag-button`}
                id="draghandle"
                onMouseDown={handler}
              >
                <img src={size.x < limitSize.x ? mano : mano2 } onDragStart={preventDragHandler} alt="mano" width="100%" height="100%" />
              </IconButton>
          </div>

          {filteredReservationsForTime.map((item, index, arr) => (
          <>
            {(((index + 1) >= limiteInferiorReservas) && ((index + 1) <= limiteSuperiorReservas)) ? 
              <div 
                key={index}
                className={`${size.x >= limitSize.x ? 'border-menu-drag--section pl-1 ' : 'border-menu-drag--section pl-1 margin-image2'} ${item.id == imageNode?.id ? "border-menu-drag--section-selected" : ""}`}
                onClick={() => {
                  setSelectedItem(false);
                  setImageNode(null);
                  
                  setTimeout(() => {
                    setImageNode(item);
                    setSelectedItem(true);
                    setReservacionNum(index+1);
                    console.log('====================================');
                    console.log(item);
                    console.log('====================================');
                  }, 500);
                }}
              >
                <div className={`${size.x >= limitSize.x ? "w25 justify-around" : ""} dflex flex-row`}>
                  {size.x > 400 ? 
                    <button type="button" className="photocontainer photocontainer2" width="100px" height="50px" onClick={() => openImageDialog((item.photo === null || item.photo === '') ? DefaulImage : item.photo)}>
                      {(item.photo === null || item.photo === '') ? (
                        <>
                          <img src={DefaulImage} alt="" width="100%" />
                        </>
                      ) : (
                        <>
                          <img src={item.photo} alt="" width="100%" />
                        </>
                      )}
                    </button> :
                    null }
                  <Typography className={size.x < 400 ? 'self-center pt-1e' : 'pt-1e'}><span className='bold-font'>{index+1}.</span></Typography>
                  <div className='box-text-reserve'>
                    <Typography>{item.time}</Typography>
                    <Typography>
                      {item.name !== null && (item.clientStatus === 'Pendiente' || item.clientStatus === 'Invitado') && (
                        <>
                          <span className='bold-font'>
                            {item.name}
                          </span>
                        </>
                      )}
                      {item.name !== null && (item.clientStatus === 'Aceptado' || item.clientStatus === 'Invitado') && (
                        <>
                          <span className='bold-font'>
                            {item.name}
                          </span>
                        </>
                      )}
                      {item.name !== null && item.clientStatus !== 'Aceptado' && item.clientStatus !== 'Invitado' && (
                        <>
                          <span className='bold-font'>
                            {item.name}
                          </span>
                        </>
                      )}
                      {item.name === null && (
                        <span className='bold-font'>
                          Sin datos
                        </span>
                      )}
                    </Typography>
                  </div>
                  <div 
                    onClick={() =>{
                      obtenerMesas(item.id);

                      setModalMesasOfReserva(true);

                    }}
                    className='icon-mesa2 dFlex flex-column align-center mt-1 mb-1 justify-center'>
                    <img src={mesa1} alt="mesa" />
                  </div>
                </div>
                

                {size.x >= limitSize.x ? 
                  <div className='box-text-reserve contacto-section w10'>
                    <Typography className='tiny-font3 text-overflow'>{item.phone}</Typography>
                    <Typography className='tiny-font3 text-overflow'>{item.email}</Typography>
                  </div> :
                null }

                {size.x >= limitSize.x ? 
                  <div className='box-text-reserve categoria-section w10'>
                    {item.categories.map((category) => prepareCategoryList(category))}
                    {item.categories.length == 0 ? "Sin datos" : ""}
                  </div> :

                null }
                
                {size.x >= limitSize.x ? 
                  <div className='box-text-reserve zona-section w5'>
                    <Typography className='tiny-font3'>{item.table != null ? item.table : "Sin datos"}</Typography>
                  </div> :
                null }
                
                {size.x >= limitSize.x ? 
                  <div className='box-text-reserve personas-section w5'>
                    <Typography className='tiny-font3'>{item.people}</Typography>
                  </div> :
                null }

                {size.x >= limitSize.x ? 
                  <div className='box-text-reserve status-section w20 tiny-font5'>
                    {(item.status !== 'CANCELADO POR EL CLIENTE')
                    ? (
                      <MenuReservationStatus
                        id={item.id}
                        status={(item.status === 'EN ESPERA') ? 'ANOTADA POR EL RESTAURANTE' : (item.status === 'LISTA DE ESPERA') ? 'PENDIENTE POR CONTESTAR' : item.status}
                        type={item.type}
                        catalog={catalogReservationStatus}
                        reservationDate={item.dateRaw}
                        setNewStatus={() => {
                          setApiCallConfig({
                            ...pagination,
                          });
                        }}
                      />
                    ) : (
                      <>
                        <span className="canceled">
                          {item.status}
                        </span>
                      </>
                    )}
                  </div> :
                null }

                {size.x >= limitSize.x ? 
                  <div className='box-text-reserve comentarios-section w10'>
                    <Typography className='tiny-font3'>
                      {item.comments.length > 0 ? item.comments : 'Sin comentarios'}
                    </Typography>
                  </div> :
                null }

                {size.x >= limitSize.x ? 
                  <div className='box-text-reserve visitas-section w10'>
                    <Typography className='tiny-font3'>{(item.visits) ? item.visits : 0}</Typography>
                  </div> :
                null }

                {size.x >= limitSize.x ?
                  <div className='w5'>
                    
                  </div> :
                null }
              </div>
            : null}
          </>
        ))}         

          <div className='border-menu-drag--footer'>
            <IconButton
              variant="outlined"
              className='floormap-drag-button'
              onClick={() => { 
                setLimiteInferiorReservas(limiteInferiorReservas - 4)
                setLimiteSuperiorReservas(limiteSuperiorReservas - 4) 
              }}
              disabled={!(limiteInferiorReservas != 1)}
            >
              <ArrowBackIosIcon/>
            </IconButton>
            <IconButton
              variant="outlined"
              className='floormap-drag-button'
              onClick={() => { 
                setLimiteInferiorReservas(limiteInferiorReservas + 4) 
                setLimiteSuperiorReservas(limiteSuperiorReservas + 4) 
              }}
              disabled={!(limiteSuperiorReservas < limiteReservas)}
            >
              <ArrowForwardIosIcon/>
            </IconButton>
          </div>
        </div>
      </Grid>
      <Grid item md={8} id='boxLimit' className='dFlex flex-column align-center justify-center'>
        <div className='mb-2 dFlex flex-row align-center justify-between w99'>
          <div className={`${(sizeScreen == 1 ? "flex-row" : sizeScreen == 0.8 ? "flex-column" : "flex-column" )} dFlex align-center justify-around w100`}> 
            <div className={`${(sizeScreen == 1 ? "justify-between flex-row" : sizeScreen == 0.8 ? "justify-around flex-row mb-2 w100" : "justify-around flex-column mb-2" )} dFlex align-center`}>
              {floors.length > 0 ? 
              <Select
                native
                className={`${sizeScreen == 0.4 ? "mb-2" : ""} border2`}
                onChange={
                  e => {
                    let zoneNombre = "";

                    floors.forEach((f) => {
                      if(f.id == e.target.value){
                        zoneNombre = f.nombreDelPiso;
                      }
                    });

                    zoneNombre = formatearTextoZona(zoneNombre);

                    setZonaActual(zoneNombre);

                    localStorage.setItem('ReservationsConfigZone', zoneNombre);

                    setApiCallConfig({...apiCallConfig,
                      zone: zoneNombre}
                    );


                    cambiarPiso(e.target.value);
                    obtenerEscala(e.target.value);
                  }
                }
                IconComponent={() => (<ExpandMoreIcon htmlColor={"black"}/>)}
              >
                {/* <option key={-1} value={-1} >Todas las zonas</option> */}
                {floors.map((floor, index)  => {
                  return(<option selected={index == 0} key={floor.id} value={floor.id} >{floor.nombreDelPiso}</option>)
                })}
              </Select> : null
              }
              <Select
                native
                className={`${sizeScreen == 0.4 ? "mb-2" : ""} border2`}
                IconComponent={() => (<ExpandMoreIcon htmlColor={"black"}/>)}
                defaultValue={filterTimeOption}
                onChange={(e) => {
                  setFilterTimeOption(e.target.value);
                }}
              >
                <option selected={filterTimeOption == 0} value={0} key={30} >TODAS LAS HORAS</option>
                <option selected={filterTimeOption == 1} value={1} key={31} >Desayuno</option>
                <option selected={filterTimeOption == 2} value={2} key={32} >Almuerzo</option>
                <option selected={filterTimeOption == 3} value={3} key={33} >Cena</option>
                <option disabled selected={filterTimeOption == 4} value={4} key={34} >Hora Especifica</option>
              </Select>
              <div className='dFlex flex-row align-center justify-between'>
                <IconButton
                  onClick={() => { 
                    setFilterTimeOption(0);
                    setIsCalendarOpen(true) }}
                  variant="outlined"
                  className='floormap-icon4'
                >
                  <Typography >{selectedDate}</Typography>
                </IconButton>
              </div>
            </div>
            <div className='dFlex flex-row align-center justify-between'>
              <div className='dFlex flex-row align-center justify-between'>
                <IconButton
                  variant="outlined"
                  className='floormap-icon3'
                  onClick={() => {
                    console.log('====================================');
                    console.log(filterdReservations);
                    console.log('====================================');
                    openCommentDialog()
                  }}
                >
                  <Typography variant="h6">?</Typography>
                </IconButton>
              </div>
              <div className='dFlex flex-row align-center justify-between'>
                <IconButton
                  variant="outlined"
                  className='floormap-icon'
                  onClick={() => {
                    document.querySelector('#time-picker-opening').previousSibling.children[0].click();           
                  }}
                >
                  <img src={reloj} alt="reloj" width="100%" height="100%" />
                </IconButton>
                <div className='timePicker'>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>

                    <KeyboardTimePicker
                      ampm
                      margin="normal"
                      id="time-picker-opening"
                      format="HH:mm"
                      onChange={(time, value) => { 
                        console.log(time);
                        setHoraFiltro(value);
                        setFilterTimeOption(4);
                      }}
                      KeyboardButtonProps={{
                        'aria-label': 'change time',
                      }}
                      className="restaurant-schedule__time-picker"
                      invalidDateMessage="No es un formato válido"
                      keyboardIcon={<AccessTimeIcon />}
                      InputProps={{ readOnly: true }}
                      inputVariant="standard"
                      InputAdornmentProps={{ position: 'start' }}
                      cancelLabel="CANCELAR"
                      okLabel="OK"
                    />
                  </MuiPickersUtilsProvider>
                </div>
              </div>
              <div className='dFlex flex-row align-center justify-between'>
                <IconButton
                  disabled={!estadoBotonMasZoom}
                  variant="outlined"
                  className='floormap-icon'
                  onClick={(e) => {
                    zoomFunc(e, 1)
                  }}
                >
                  <img src={zoomMas} alt="Acercar" width="100%" height="100%" />
                </IconButton>
                <IconButton
                  disabled={!estadoBotonMenosZoom}
                  variant="outlined"
                  className='floormap-icon'
                  onClick={(e) => {
                    zoomFunc(e, 2)
                  }}
                >
                  <img src={zoomMenos} alt="Alejar" width="100%" height="100%" />
                </IconButton>
                <IconButton
                  disabled={!estadoBotonMenosZoom}
                  variant="outlined"
                  className='floormap-icon'
                  onClick={(e) => {
                    zoomFunc(e, 0)
                  }}
                >
                  <img src={reajustar} alt="Reajustar" width="100%" height="100%" />
                </IconButton>
              </div>
            </div>
          </div>
        </div>
        <Modal
          keepMounted
          open={modalMesasReservacion}
          onClose={cerrarModalMesasReservacion}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='modal__box2'>
            <Typography className='mb-1'>Las mesa necesita más espacio, ¿repartir así la reservación?</Typography>
            <Grid container className='w80i mt-1'>
              {mesasVarias.map((mesa, indice) => {
                
                const mapeo = Object.freeze({
                  "1": mesa1,
                  "2": mesa2,
                  "3": mesa3,
                  "4": mesa4,
                  "5": mesa5,
                  "6": mesa6,
                  "7": mesa7,
                  "8": mesa8,
                  "9": mesa9,
                  "10": mesa10,
                  "11": mesa11,
                  "12": mesa12,
                  "13": mesa13,
                });

                return (
                  <Grid item md={mesasVarias.length == 1 ? 12 : mesasVarias.length == 2 ? 6 : 4} >
                    <div className='icon-mesa2 dFlex flex-column align-center justify-center'>
                      <img src={mapeo[mesa.type]} alt="mesa" />
                      <Typography className='icon-mesa2--texto'>{mesa.numMesa}</Typography>     
                    </div>
                  </Grid>
                )
              })}
            </Grid>
            <div className='modal__box__menu justify-evenly mt-1'>
              <Button
                variant="contained"
                color="primary"
                className="botonGuardar"
                onClick={cerrarModalMesasReservacion}
              >
                Cerrar
              </Button>
              <Button
              variant="contained"
              color="primary"
              className="botonGuardar"
              onClick={reservarMesas}
            >
              Aceptar
            </Button>
            </div>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={modalMesasOfReserva}
          onClose={cerrarModalMesasOfReserva}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='modal__box2 pl-1 pb-1 pt-1 pr-1'>
            <Typography className='mb-1'>{arrayMesasOfReserva.length > 0 ? "Está reserva tiene las siguientes mesas:" : "Esta reserva no tiene mesas"}</Typography>
            <Grid container className='w80i mt-1'>
              {arrayMesasOfReserva.map((mesa, indice) => {
                
                const mapeo = Object.freeze({
                  "1": mesa1,
                  "2": mesa2,
                  "3": mesa3,
                  "4": mesa4,
                  "5": mesa5,
                  "6": mesa6,
                  "7": mesa7,
                  "8": mesa8,
                  "9": mesa9,
                  "10": mesa10,
                  "11": mesa11,
                  "12": mesa12,
                  "13": mesa13,
                });

                return (
                  <Grid item md={arrayMesasOfReserva.length == 1 ? 12 : arrayMesasOfReserva.length == 2 ? 6 : 4} >
                    <div className='icon-mesa2 dFlex flex-column align-center justify-center'>
                      <img src={mapeo[mesa.type]} alt="mesa" />
                      <Typography className='icon-mesa2--texto'>{mesa.numMesa}</Typography>     
                    </div>
                  </Grid>
                )
              })}
            </Grid>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={modalMesasReservacionError}
          onClose={cerrarModalMesasReservacionError}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='modal__box2 pl-1 pb-1 pt-1 pr-1'>
            <Typography>Las mesa necesita más espacio, y no hay mesas para repartir la reservación</Typography>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={modalMesasReservacionErrorOrden}
          onClose={cerrarModalMesasReservacionErrorOrden}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='modal__box2 pl-1 pb-1 pt-1 pr-1'>
            <Typography>Las mesa necesita más espacio, hay mesas para distribuir la reservación, pero debe reubicar la mesa actual</Typography>
          </Box>
        </Modal>
        <Modal
          keepMounted
          open={modalMesaOcupada}
          onClose={cerrarModalMesaOcupada}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box className='modal__box2 pl-1 pb-1 pt-1 pr-1'>
            <Typography>La mesa qué selecciono se encuentra ocupada</Typography>
          </Box>
        </Modal>
        <Grid className='dFlex flex-column align-center justify-center mb-4'>
          <Stage
            item md={12}
            width={801 * sizeScreen}
            height={401 * sizeScreen}
            ref={stageRef}
            onClick={() =>{
              setSelectedItem(false);
              setImageNode(null);
            }}
            scaleX={state.stageScale}
            scaleY={state.stageScale}
            onDragMove={(e) =>{
              limitarDrag(e,1);
            }}
          >
            <Layer>
              {linesA}
              {linesB}
              {images.map((image) => {
                return <URLImage image={image} onBasicClick={onBasicClick} limitarDrag={limitarDrag} renovarCordenadas={renovarCordenadas} />;
              })}
            </Layer>
          </Stage>
        </Grid>
      </Grid>
      <Grid item md={2} >
        <div className='mb-4'>
          {selectedItem && renderClientMenu()}
        </div>
        {loading ?
          <SpinnerLoader />
          : null        
        }
      </Grid>
    </Grid>

    </>
  );
};

export default FloormapGestionReservaciones;